import { Button, Modal, Stack, TextInput, Text } from '@mantine/core'
import { useForm } from '@mantine/form'
import { FaSave } from 'react-icons/fa'

interface NotesModalProps {
    appointmentId: number
    modalState: boolean
    cancel: () => void
    continueWithResult: (notes: NotesForm) => void
}

interface NotesForm {
    notes: string
}

export default function NotesModal(props: NotesModalProps) {
    function close() {
        props.cancel()
        form.reset()
    }

    const form = useForm<NotesForm>({
        initialValues: {
            notes: ''
        },
        validate: {
            notes: (value) => value.length ? null : 'Notes can not be empty'
        }
    })

    return (
        <Modal
            withCloseButton={false}
            centered
            overlayOpacity={0.55} overlayBlur={3}
            opened={props.modalState}
            onClose={() => {
                close()
            }}
            padding={'xl'}
            radius={'lg'}
        >
            <form onSubmit={
                form.onSubmit((values) => {
                    props.continueWithResult(values)
                    close()
                })
            }>
                <Stack align={'stretch'} spacing={'xl'}>
                    <Text size='xl' weight={600}
                        style={{ textAlign: 'center' }}>Add notes</Text>

                    <TextInput
                        radius={'lg'}
                        size='lg'
                        type='text'
                        variant='filled'
                        placeholder='Note'
                        {...form.getInputProps('notes')}>
                    </TextInput>

                    <Button
                        type='submit'
                        fullWidth
                        size='lg'
                        radius={'md'}
                        leftIcon={<FaSave></FaSave>}
                        styles={{
                            inner: {
                                justifyContent: 'start'
                            }
                        }}
                    >
                    Save
                    </Button>
                </Stack>
            </form>
        </Modal>
    )
}
