import create from 'zustand'
import axiosClient from '../../../api/ApiClient'
import Patient from '../../../models/patient'
import User from '../../../models/user'

interface StaffViewStore {
  user: User | null
  isFetchPending: boolean
  controls: {
    fetchStaff: (
        id: Patient['id'],
    ) => void
  }
}

const useStaffViewStore = create<StaffViewStore>((set) => ({
    user: null,
    isFetchPending: false,
    controls: {
        fetchStaff: (id) => {
            set(() => ({
                isFetchPending: true
            }))

            void axiosClient.get(`/user/${id}`).then(response => response.data).then(user => {
                set(() => ({
                    user,
                    isFetchPending: false
                }))
            })
        }
    }
}))

export { useStaffViewStore }
