import { Button, Divider, Grid, Modal, TextInput, NumberInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import moment from 'moment'
import { Trash2 } from 'react-feather'
import { FaPlusSquare, FaSave } from 'react-icons/fa'
import Doctor from '../../../components/Doctor'
import Appointment from '../../../models/appointment'
import { Medicine } from '../../../models/medicine'

interface MedicinesModalProps {
    appointment: Appointment
    modalState: boolean
    cancel: () => void
    continueWithResult: (notes: MedicinesForm) => void
}

interface MedicinesForm {
    medicines: Medicine[]
}

export default function MedicinesModal(props: MedicinesModalProps) {
    function close() {
        props.cancel()
        form.reset()
    }

    const form = useForm<MedicinesForm>({
        initialValues: {
            medicines: [{
                name: '',
                quantity: 0,
                notes: ''
            }]
        },
        validate: {
            medicines: {
                name: (value) => value.length ? null : 'Name is required'
            }
        }
    })

    const medicines = form.values.medicines.map((_medicine, index) => (<>
        <Grid.Col span={7} key={`name-${index}`}>
            <TextInput
                size='lg'
                placeholder='Medicine name'
                variant='filled'
                withAsterisk
                radius={'lg'}
                {...form.getInputProps(`medicines.${index}.name`)}
                style={{ flexGrow: 1 }}></TextInput>
        </Grid.Col>
        <Grid.Col span={5} key={`quantity-${index}`}>
            <NumberInput
                size='lg'
                placeholder='Quantity'
                hideControls
                variant='filled'
                radius={'lg'}
                {...form.getInputProps(`medicines.${index}.quantity`)}
                style={{ flexGrow: 1 }}></NumberInput>
        </Grid.Col>
        <Grid.Col span={10} key={`notes-${index}`}>
            <TextInput
                size='lg'
                placeholder='Details'
                variant='filled'
                radius={'lg'}
                {...form.getInputProps(`medicines.${index}.notes`)}
                style={{ flexGrow: 1 }}></TextInput>
        </Grid.Col>
        <Grid.Col span={2} key={`delete-${index}`}>
            <Button
                variant='outline'
                size='lg'
                radius={'lg'}
                onClick={() => form.removeListItem('medicines', index)}>
                <Trash2></Trash2>
            </Button>
        </Grid.Col>

        <Grid.Col>
            <Divider></Divider>
        </Grid.Col>
    </>))

    return (
        <Modal
            withCloseButton={false}
            centered
            overlayOpacity={0.55} overlayBlur={3}
            opened={props.modalState}
            onClose={() => {
                close()
            }}
            padding={'xl'}
            radius={'lg'}
            size={'xl'}
        >
            <form onSubmit={form.onSubmit((values) => {
                props.continueWithResult(values)
                close()
            })}>
                <Grid>
                    <Grid.Col>
                        {props.appointment.doctor && <Doctor doctor={props.appointment.doctor}></Doctor>}
                    </Grid.Col>

                    <Grid.Col>
                        <Divider></Divider>
                    </Grid.Col>

                    <Grid.Col span={5}>
                        <TextInput
                            size='lg'
                            placeholder='Name and first name'
                            variant='filled'
                            radius={'lg'}
                            readOnly
                            value={props.appointment.patient?.fullname}
                            disabled
                            style={{ flexGrow: 1 }}></TextInput>
                    </Grid.Col>
                    <Grid.Col span={3}>
                        <TextInput
                            size='lg'
                            placeholder='Age'
                            variant='filled'
                            radius={'lg'}
                            readOnly
                            value={moment().get('year') - moment(props.appointment.patient?.birthday).get('year')}
                            disabled
                            style={{ flexGrow: 1 }}></TextInput>
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <TextInput
                            size='lg'
                            placeholder='Birth Date'
                            variant='filled'
                            radius={'lg'}
                            readOnly
                            value={moment(props.appointment.patient?.birthday).format('LL')}
                            disabled
                            style={{ flexGrow: 1 }}></TextInput>
                    </Grid.Col>

                    <Grid.Col>
                        <Divider></Divider>
                    </Grid.Col>

                    { medicines }

                    <Grid.Col span={12}>
                        <Button
                            onClick={() => {
                                form.insertListItem('medicines', { name: '', quantity: '', notes: '' })
                            }}
                            size='lg'
                            radius={'md'}
                            variant='filled'
                            color='yamnaGreen'
                            leftIcon={<FaPlusSquare></FaPlusSquare>}
                            styles={{
                                inner: {
                                    justifyContent: 'start'
                                }
                            }}
                        >
                        Add medicine
                        </Button>
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Button
                            type='submit'
                            size='lg'
                            radius={'md'}
                            leftIcon={<FaSave></FaSave>}
                            styles={{
                                inner: {
                                    justifyContent: 'start'
                                }
                            }}
                        >
                        Save and print
                        </Button>
                    </Grid.Col>
                </Grid>
            </form>
        </Modal>
    )
}
