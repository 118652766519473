import { Button, Card, Grid, Group, Image, Paper, ScrollArea, Space, Stack, Text } from '@mantine/core'
import moment from 'moment'
import { useEffect } from 'react'
import { AtSign, Calendar, Clock, Home, Link2, MapPin, Smartphone, Star, User } from 'react-feather'
import { FaTicketAlt } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import Desktop from '../../assets/Images/desktop.svg'
import CalendarWeek from '../../components/CalendarWeek'
import Review from '../../components/Review'
import { useWebpageStore } from './webPageStore'

export default function WebPage() {
    const store = useWebpageStore()
    const navigate = useNavigate()

    useEffect(() => {
        store.controls.fetchCurrentClinic()
    }, [store.controls])

    return <>
        <Grid
            columns={3}
            p={'lg'}
            style={{
                width: '100%'
            }}
        >
            <Grid.Col span={1}>
                <Card
                    radius={'lg'}
                    shadow={'xs'}
                    p={'xl'}
                    style={{
                        height: '80%'
                    }}
                >
                    <ScrollArea style={{ height: '100%' }}>
                        <User></User>
                        <Text fw={700}>About</Text>
                        <Space h={'md'}></Space>
                        <Stack align={'flex-start'}>
                            <Group noWrap align={'top'}>
                                <Home size={16}></Home>
                                <Text>{ store.currentClinic?.name ?? 'Loading...' }</Text>
                            </Group>
                            {/* <Group noWrap align={'top'}>
                                <Info size={24}></Info>
                                <Text>{ store.currentClinic?.address }</Text>
                            </Group> */}
                            <Group noWrap align={'center'}>
                                <Smartphone size={16}></Smartphone>
                                <Text>{ store.currentClinic?.phoneNumber ?? 'Loading...' }</Text>
                            </Group>
                            <Group noWrap align={'center'}>
                                <AtSign size={16}></AtSign>
                                <Text>contact@clinikia.doc</Text>
                            </Group>
                            <Group noWrap align={'top'}>
                                <MapPin size={16}></MapPin>
                                <Text>{ store.currentClinic?.address ?? 'Loading...' }</Text>
                            </Group>
                            <Paper radius={'lg'} style={{ overflow: 'hidden' }}>
                                <iframe
                                    // eslint-disable-next-line max-len
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d95882.13650631218!2d69.2322304!3d41.3237248!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8b40d847941d%3A0x5765a18b352df71e!2sTashkent%20City%20Park!5e0!3m2!1sru!2s!4v1675239449889!5m2!1sru!2s"
                                    width="100%" height="300"
                                    style={{ border: 0 }}
                                    allowFullScreen
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade">
                                </iframe>
                            </Paper>
                            <Button
                                variant='subtle'
                                onClick={() => navigate('/profile')}
                            >
                                Edit info →
                            </Button>
                        </Stack>
                    </ScrollArea>
                </Card>
            </Grid.Col>
            <Grid.Col span={1}>
                <Card
                    shadow={'xs'}
                    radius={'lg'}
                    style={{
                        height: '80%'
                    }}
                    p={'xl'}
                >
                    <ScrollArea style={{ height: '100%' }}>
                        <Stack>
                            <Calendar></Calendar>
                            <Text fw={700}>Availability</Text>
                            <CalendarWeek weekDay={moment().format()}></CalendarWeek>

                            <Text>cabinet ouvert de 08:00 à 17:00</Text>

                            <Group
                                grow
                                style={{
                                    backgroundColor: '#F4F5F5',
                                    borderRadius: '8px'
                                }}
                                p={'xl'}>
                                <Stack>
                                    <Clock></Clock>
                                    <Text fw={700}>Availability at</Text>

                                    <Text fw={700} fz={24}>≈ 13:20</Text>

                                </Stack>
                                <Stack>
                                    <FaTicketAlt></FaTicketAlt>
                                    <Text fw={700}>Queue</Text>

                                    <Text fw={700} fz={24}>8</Text>

                                </Stack>
                            </Group>
                        </Stack>
                    </ScrollArea>
                </Card>
            </Grid.Col>
            <Grid.Col span={1}>
                <Stack
                    spacing={'xl'}
                    style={{
                        height: '80%'
                    }}
                >
                    <Button
                        size='lg'
                        radius={'md'}
                        leftIcon={<Link2></Link2>}
                        styles={{
                            inner: {
                                justifyContent: 'start',
                                paddingLeft: '10px',
                                paddingRight: '10px'
                            }
                        }}
                        style={{
                            flexShrink: 0
                        }}
                    >
                        Share your webpage link
                    </Button>

                    <Group grow>
                        <Card
                            shadow={'xs'}
                            radius={'lg'}
                        >
                            <Stack spacing={'xs'}>
                                <Image
                                    src={Desktop}
                                    height={24}
                                    width={24}
                                ></Image>

                                <Text fw={700}>Web visits</Text>
                                <Text
                                    fw={700}
                                    fz={'24px'}
                                >1460</Text>
                                <Text fw={400}>This week</Text>
                            </Stack>
                        </Card>
                        <Card
                            shadow={'xs'}
                            radius={'lg'}
                        >
                            <Stack spacing={'xs'}>
                                <User></User>

                                <Text fw={700}>Conversion</Text>
                                <Text
                                    fw={700}
                                    fz={'24px'}
                                >35%</Text>
                                <Text fw={400}>This week</Text>
                            </Stack>
                        </Card>
                    </Group>
                    <Card
                        radius={'lg'}
                        shadow={'xs'}
                        p={'xl'}
                        style={{
                            height: '100%'
                        }}
                    >

                        <ScrollArea style={{ height: '100%' }}>
                            <Star></Star>
                            <Group align={'center'} spacing={2}>
                                <Text fw={700}>Reviews 4.5</Text>
                                <Star
                                    fill={'#FFB200'}
                                    color={'#FFB200'}
                                    size={16}
                                ></Star>
                                <Text color={'dimmed'}>&nbsp;(5 reviews)</Text>
                            </Group>
                            <Space h={'md'}></Space>

                            <Stack spacing={'lg'}>
                                <Review
                                    author='Test author'
                                    reviewDate={moment().format()}
                                    reviewRate={3}
                                    key={1}
                                ></Review>
                                <Review
                                    author='Test author'
                                    reviewDate={moment().format()}
                                    reviewRate={4}
                                    key={2}
                                    reviewText='Hello world'
                                ></Review>
                                <Review
                                    author='Test author'
                                    reviewDate={moment().format()}
                                    reviewRate={4}
                                    key={3}
                                    reviewText='Hello world'
                                ></Review>
                                <Review
                                    author='Test author'
                                    reviewDate={moment().format()}
                                    reviewRate={4}
                                    key={4}
                                    reviewText='Hello world'
                                ></Review>
                            </Stack>
                        </ScrollArea>
                    </Card>
                </Stack>
            </Grid.Col>
        </Grid>
    </>
}
