import {
    Button,
    createStyles,
    Drawer,
    Grid,
    Group,
    LoadingOverlay,
    ScrollArea,
    Select,
    Stack,
    Switch,
    Tabs,
    Text,
    TextInput
} from '@mantine/core'
import { useForm } from '@mantine/form'
import parseNumber, { isValidPhoneNumber } from 'libphonenumber-js'
import { useEffect, useMemo } from 'react'
import { Save, XCircle } from 'react-feather'
import { FaCheck, FaTimes } from 'react-icons/fa'
import PhoneInput from 'react-phone-number-input/input'
import { Schedule, WeekDays } from '../../../models/user'
import { endHoursArray, startHoursArray } from '../../../utils/hours'
import { CreateStaffReqDto, useAddStaffStore } from './addStaffStore'

interface AddStaffDrawerProps {
    close: () => void
    state: boolean
}

interface AddStaffFormFields {
    firstName: string
    lastName: string
    phone: string
    email: string
    speciality: string
    password: string

    roleId: string
    permissions: string[]
    schedule: Schedule
}

const useStyles = createStyles(() => ({
    phoneInput: {
        border: '1px solid transparent',
        backgroundColor: '#f1f3f5',
        fontFamily: 'Poppins',
        height: '50px',
        WebkitTapHighlightColor: 'transparent',
        lineHeight: '48px',
        appearance: 'none',
        resize: 'none',
        boxSizing: 'border-box',
        fontSize: '18px',
        width: '100%',
        color: '#000',
        display: 'block',
        textAlign: 'left',
        minHeight: '50px',
        paddingLeft: '16px',
        paddingRight: '16px',
        borderRadius: '16px',
        outline: 'none',
        '&::placeholder': {
            color: 'rgba(0, 0, 0, 0.3)'
        },
        '&:focus': {
            borderColor: '#49BAE4!important',
            outline: 'none'
        },
        '&:disabled': {
            backgroundColor: '#f1f3f5',
            color: '#909296',
            opacity: 0.6,
            outline: 'none'
        }
    }
}))

function isNumeric(str: string | number) {
    if (typeof str !== 'string') return false // we only process strings!

    return !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export default function AddStaffDrawer(props: AddStaffDrawerProps) {
    const store = useAddStaffStore()
    const { classes } = useStyles()

    useEffect(() => {
        void store.controls.fetchRoles()
    }, [store.controls])

    const form = useForm<AddStaffFormFields>({
        initialValues: {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            speciality: '',
            roleId: '',
            password: '',
            permissions: [],
            schedule: {}
        },
        validate: {
            firstName: (value) => (value.trim().length > 0 ? null : 'Required'),
            lastName: (value) => (value.trim().length > 0 ? null : 'Required'),
            phone: (value) => {
                if (!value || value.trim().length <= 0) return 'Required'

                if (!isValidPhoneNumber(value, 'DZ')) return 'Invalid phone number'
            },
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
            speciality: (value) => (value.trim().length > 0 ? null : 'Required'),
            roleId: (value) => isNumeric(value) ? null : 'Required',
            password: (value) => (value.trim().length > 6 ? null : 'Min length of password is 6 characters')
        },
        validateInputOnChange: true
    })

    const roleOptions = useMemo(() => {
        return store.roles.map(role => ({
            label: role.name,
            value: role.id.toString()
        }))
    }, [store.roles])

    const permissions = [
        { label: 'Statistics', value: 'statistics' },
        { label: 'Patients', value: 'patients' },
        { label: 'Appointments', value: 'appointments' },
        { label: 'Payments', value: 'payments' },
        { label: 'Staff', value: 'staff' },
        { label: 'Page web', value: 'webpage' }
    ]

    function submit() {
        const values = form.values

        const phone = parseNumber(values.phone, 'DZ')

        if (phone) {
            const dto: CreateStaffReqDto = {
                phoneNumber: phone.number.toString(),
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
                password: values.password,
                roleId: parseInt(values.roleId),
                speciality: values.speciality,
                permissions: values.permissions,
                schedule: values.schedule
            }

            void store.controls.addStaff(dto).then(() => {
                form.reset()
                props.close()
            })
        }
    }

    return (
        <Drawer
            position='right'
            opened={props.state}
            onClose={() => props.close()}
            padding='xl'
            size={'xl'}
            styles={{
                closeButton: {
                    display: 'none'
                }
            }}>
            <form onSubmit={form.onSubmit(() => submit())}>
                <LoadingOverlay visible={store.saveInProgress}></LoadingOverlay>
                <h2>Add new member of staff</h2>
                <ScrollArea offsetScrollbars style={{ height: 850 }}>
                    <Grid grow gutter="xs">
                        <Grid.Col span={12}>
                            <TextInput required size="lg"
                                radius="lg" variant="filled"
                                placeholder="First name" {...form.getInputProps('firstName')}
                                classNames={{ input: 'customInput' }} />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextInput required size="lg"
                                radius="lg" variant="filled"
                                placeholder="Last name" {...form.getInputProps('lastName')}
                                classNames={{ input: 'customInput' }} />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <PhoneInput
                                country='DZ'
                                className={classes.phoneInput}
                                placeholder="Phone number"
                                {...form.getInputProps('phone')}
                            ></PhoneInput>
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextInput required size="lg"
                                radius="lg" variant="filled"
                                placeholder="Adresse e-mail" {...form.getInputProps('email')}
                                classNames={{ input: 'customInput' }} />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextInput required size="lg"
                                radius="lg" variant="filled"
                                placeholder="Speciality" {...form.getInputProps('speciality')}
                                classNames={{ input: 'customInput' }} />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextInput required size="lg"
                                radius="lg" variant="filled"
                                type={'password'}
                                placeholder="Password"
                                {...form.getInputProps('password')}
                                classNames={{ input: 'customInput' }} />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Select
                                data={roleOptions}
                                required
                                size="lg"
                                radius="lg"
                                clearable={false}
                                variant="filled"
                                label="Role"
                                {...form.getInputProps('roleId')}
                                classNames={{ input: 'customInput' }}
                            />
                        </Grid.Col>

                        <Tabs
                            mt={25}
                            defaultValue="permissions"
                            w={'100%'}
                        >
                            <Tabs.List grow>
                                <Tabs.Tab value="permissions">Permissions</Tabs.Tab>
                                <Tabs.Tab value="schedule">Schedule</Tabs.Tab>
                            </Tabs.List>
                            <Tabs.Panel value='permissions'>
                                <Grid.Col span={12}>
                                    <Switch.Group
                                        defaultValue={['react']}
                                        value={form.values.permissions}
                                        onChange={(values) => form.setFieldValue('permissions', values)}
                                    >
                                        <Stack
                                            align={'stretch'}
                                            w={'100%'}
                                            spacing={4}
                                        >
                                            { permissions.map(permission => (
                                                <Switch
                                                    labelPosition='left'
                                                    label={permission.label}
                                                    key={permission.value}
                                                    size='md'
                                                    mb={'md'}
                                                    value={permission.value}
                                                    thumbIcon={
                                                        form.values.permissions.includes(permission.value)
                                                            ? (<FaCheck color='#000'></FaCheck>)
                                                            : (<FaTimes></FaTimes>)
                                                    }
                                                    styles={{
                                                        labelWrapper: {
                                                            fontWeight: 600,
                                                            flexGrow: 1,
                                                            flexShrink: 1
                                                        }
                                                    }}
                                                >
                                                </Switch>)) }
                                        </Stack>
                                    </Switch.Group>
                                </Grid.Col>
                            </Tabs.Panel>
                            <Tabs.Panel value='schedule' mt={'md'}>
                                <Grid.Col span={12}>
                                    {
                                        Object.values(WeekDays).map((weekday) => {
                                            let content = <Text
                                                color="gray"
                                                align='end'
                                            >Day off</Text>

                                            if (weekday in form.values.schedule) {
                                                content = <Group
                                                    noWrap
                                                    spacing={0}
                                                >
                                                    <Select
                                                        value={form.values.schedule[weekday]?.start}
                                                        onChange={(value) => {
                                                            form.setFieldValue(`schedule.${weekday}.start`, value)
                                                        }}
                                                        defaultValue={'08:00'}
                                                        data={startHoursArray}
                                                        classNames={{ input: 'customSelectDate' }}
                                                        variant="filled"
                                                        radius="lg"
                                                        style={{ flexGrow: 1, flexShrink: 1 }}
                                                        size="xs" />
                                                    <Text style={{ flexGrow: 0, flexShrink: 1 }}>-</Text>
                                                    <Select
                                                        value={form.values.schedule[weekday]?.end}
                                                        onChange={(value) => {
                                                            form.setFieldValue(`schedule.${weekday}.end`, value)
                                                        }}
                                                        data={endHoursArray}
                                                        classNames={{ input: 'customSelectDate' }}
                                                        variant="filled"
                                                        style={{ flexGrow: 1, flexShrink: 1 }}
                                                        radius="lg" size="xs" />
                                                </Group>
                                            }

                                            return (
                                                <Group
                                                    spacing={'xs'}
                                                    key={weekday}
                                                    my={'xs'}
                                                >
                                                    <Text
                                                        fw={700}
                                                        style={{
                                                            textTransform: 'capitalize',
                                                            flex: 2
                                                        }}>{weekday}</Text>
                                                    <Switch
                                                        checked={weekday in form.values.schedule}
                                                        onChange={(event) => {
                                                            const currentSchedule = form.values.schedule
                                                            if (event.currentTarget.checked) {
                                                                currentSchedule[weekday] = { start: '09:00', end: '17:00' }
                                                            } else {
                                                                // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                                                                delete currentSchedule[weekday]
                                                            }
                                                            form.setValues({ schedule: currentSchedule })
                                                        }
                                                        }
                                                        style={{ flex: 1 }}
                                                    ></Switch>
                                                    <Group grow style={{ flex: 4 }}>
                                                        {content}
                                                    </Group>
                                                </Group>
                                            )
                                        })
                                    }
                                </Grid.Col>
                            </Tabs.Panel>
                        </Tabs>
                        <Grid.Col span={12}>
                            <Group mt="xl">
                                <Button type='submit' fullWidth
                                    size="lg" leftIcon={<Save />}
                                    loading={store.saveInProgress}
                                    radius="md">Save and add</Button>
                                <Button onClick={() => props.close()} fullWidth
                                    size="lg" leftIcon={<XCircle />}
                                    color="yamnaGreen" radius="md">Cancel</Button>
                            </Group>
                        </Grid.Col>
                    </Grid>
                </ScrollArea>
            </form>
        </Drawer>
    )
}
