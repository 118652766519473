import { Group, Stack, Text } from '@mantine/core'
import moment from 'moment'
import ReviewRateStars from './ReviewRate'

interface ReviewComponentProps {
    reviewRate: number
    author: string
    reviewText?: string
    reviewDate: string
}

export default function Review(props: ReviewComponentProps) {
    return <Stack spacing={2}>
        <Text fw={600}>{props.author}</Text>
        <Group spacing={'lg'}>
            <ReviewRateStars rate={props.reviewRate}></ReviewRateStars>
            <Text
                fz={14}
                fw={400}
            >{moment(props.reviewDate).format('ll')}</Text>
        </Group>
        <Text>{props.reviewText}</Text>
    </Stack>
}
