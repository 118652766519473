import { createStyles } from '@mantine/core'
import { DefaultInputComponentProps } from 'react-phone-number-input'
import PhoneInput from 'react-phone-number-input/input'

type YamnaPhoneNumberFieldProps = {
    disabled?: boolean
    readonly?: boolean
    required?: boolean
    placeholder?: string
    onChange: (value: string) => void
} & DefaultInputComponentProps

const useStyles = createStyles(() => ({
    phoneInput: {
        border: '1px solid transparent',
        backgroundColor: '#f1f3f5',
        fontFamily: 'Poppins',
        height: '50px',
        WebkitTapHighlightColor: 'transparent',
        lineHeight: '48px',
        appearance: 'none',
        resize: 'none',
        boxSizing: 'border-box',
        fontSize: '18px',
        color: '#000',
        display: 'block',
        textAlign: 'left',
        minHeight: '50px',
        paddingLeft: '16px',
        paddingRight: '16px',
        borderRadius: '16px',
        outline: 'none',
        '&::placeholder': {
            color: 'rgba(0, 0, 0, 0.3)'
        },
        '&:focus': {
            borderColor: '#49BAE4!important',
            outline: 'none'
        },
        '&:disabled': {
            backgroundColor: '#f1f3f5',
            color: '#909296',
            opacity: 0.6,
            outline: 'none'
        }
    }
}))

export default function YamnaPhoneNumberField(props: YamnaPhoneNumberFieldProps) {
    const { classes } = useStyles()

    return <PhoneInput
        required={props.required}
        readOnly={props.readOnly}
        className={classes.phoneInput}
        disabled={props.disabled}
        placeholder={props.placeholder}
        {...props}
    ></PhoneInput>
}
