import { Button, createStyles, Grid, Group, LoadingOverlay, ScrollArea, Select, TextInput } from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import { useForm } from '@mantine/form'
import parseNumber, { isValidPhoneNumber } from 'libphonenumber-js'
import moment from 'moment'
import { Save, Trash2 } from 'react-feather'
import PhoneInput from 'react-phone-number-input/input'
import Patient, { BloodTypes } from '../../../models/patient'
import { PatchPatientReqDto, useEditPatientStore } from './editPatientStore'

interface EditPatientFormProps {
    close: () => void
    onComplete?: () => void
    patient: Patient
}

interface EditPatientFormFields {
    fullname: string
    adresse: string
    phone: string
    email: string
    birthday: Date | ''

    size: string
    weight: string
    imc: string
    diagnostic: string

    sexe: '' | Patient['sexe']
    bloodType: '' | Required<Patient>['bloodType']
}

const useStyles = createStyles(() => ({
    phoneInput: {
        border: '1px solid transparent',
        backgroundColor: '#f1f3f5',
        fontFamily: 'Poppins',
        height: '50px',
        WebkitTapHighlightColor: 'transparent',
        lineHeight: '48px',
        appearance: 'none',
        resize: 'none',
        boxSizing: 'border-box',
        fontSize: '18px',
        width: '100%',
        color: '#000',
        display: 'block',
        textAlign: 'left',
        minHeight: '50px',
        paddingLeft: '16px',
        paddingRight: '16px',
        borderRadius: '16px',
        outline: 'none',
        '&::placeholder': {
            color: 'rgba(0, 0, 0, 0.3)'
        },
        '&:focus': {
            borderColor: '#49BAE4!important',
            outline: 'none'
        },
        '&:disabled': {
            backgroundColor: '#f1f3f5',
            color: '#909296',
            opacity: 0.6,
            outline: 'none'
        }
    }
}))

function isNumeric(str: string | number) {
    if (typeof str !== 'string') return false // we only process strings!

    return !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export default function EditPatientForm(props: EditPatientFormProps) {
    const store = useEditPatientStore()
    const { classes } = useStyles()

    const form = useForm<EditPatientFormFields>({
        initialValues: {
            fullname: props.patient.fullname,
            adresse: props.patient.adresse,
            phone: props.patient.phone,
            email: props.patient.email,
            birthday: moment(props.patient.birthday).toDate(),
            sexe: props.patient.sexe,
            size: props.patient.size?.toString() ?? '',
            weight: props.patient.weight?.toString() ?? '',
            imc: props.patient.imc ?? '',
            bloodType: props.patient.bloodType ?? '',
            diagnostic: props.patient.diagnostic ?? ''
        },
        validate: {
            fullname: (value) => (value.trim().length > 0 ? null : 'Required'),
            adresse: (value) => (value.trim().length > 0 ? null : 'Required'),
            phone: (value) => {
                if (!value || value.trim().length <= 0) return 'Required'

                if (!isValidPhoneNumber(value, 'DZ')) return 'Invalid phone number'
            },
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
            birthday: (value) => {
                if (!moment(value).isValid()) return 'Invalid date'
            },
            sexe: (value) => {
                if (value.trim() === '') return 'Required'
            },
            size: (value) => {
                if (value.trim() === '') return 'Required'

                if (!isNumeric(value)) return 'Invalid size'
            },
            weight: (value) => {
                if (value.trim() === '') return 'Required'

                if (!isNumeric(value)) return 'Invalid weight'
            },
            imc: (value) => {
                if (value.trim() === '') return 'Required'
            },
            bloodType: (value) => {
                if (value.trim() === '') return 'Required'
            }
        },
        validateInputOnChange: true
    })

    const bloodTypesData = [...BloodTypes]

    function submit() {
        const values = form.values

        const phone = parseNumber(values.phone, 'DZ')
        if (phone) {
            const dto: PatchPatientReqDto = {
                ...values,
                phone: phone.number.toString(),
                weight: parseFloat(values.weight),
                size: parseFloat(values.size),
                birthday: moment(values.birthday).format(),
                sexe: values.sexe !== '' ? values.sexe : 'Male',
                bloodType: values.bloodType === '' ? undefined : values.bloodType
            }

            void store.controls.patchPatient(props.patient.id, dto).then(() => {
                form.reset()
                if (props.onComplete) { props.onComplete() }
                props.close()
            })
        }
    }

    return (
        <form onSubmit={form.onSubmit(() => submit())}>
            <LoadingOverlay visible={store.saveInProgress}></LoadingOverlay>
            <h2>Ajouter un nouveau patient</h2>
            <ScrollArea offsetScrollbars style={{ height: 850 }}>
                <Grid grow gutter="xs">
                    <Grid.Col span={12}>
                        <TextInput required size="lg"
                            radius="lg" variant="filled"
                            placeholder="Full name" {...form.getInputProps('fullname')}
                            classNames={{ input: 'customInput' }} />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput required size="lg"
                            radius="lg" variant="filled"
                            placeholder="Adresse" {...form.getInputProps('adresse')}
                            classNames={{ input: 'customInput' }} />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <PhoneInput
                            country='DZ'
                            className={classes.phoneInput}
                            placeholder="Patient phone number"
                            {...form.getInputProps('phone')}
                        ></PhoneInput>

                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput required size="lg"
                            radius="lg" variant="filled"
                            placeholder="Adresse e-mail" {...form.getInputProps('email')}
                            classNames={{ input: 'customInput' }} />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <DatePicker firstDayOfWeek="monday" required
                            size="lg" radius="lg"
                            variant="filled" placeholder="Date de naissance"
                            {...form.getInputProps('birthday')} classNames={{ input: 'customInput' }} />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Select
                            data={[{ label: 'Male', value: 'Male' }, { label: 'Female', value: 'Female' }]}
                            required
                            size="lg"
                            radius="lg"
                            clearable={false}
                            variant="filled"
                            placeholder="Gender"
                            {...form.getInputProps('sexe')}
                            classNames={{ input: 'customInput' }}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput type='number' required
                            size="lg" radius="lg"
                            variant="filled" placeholder="Taille"
                            {...form.getInputProps('size')}
                            classNames={{ input: 'customInput' }} />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput type='number' required
                            size="lg" radius="lg"
                            variant="filled" placeholder="Poids"
                            {...form.getInputProps('weight')}
                            classNames={{ input: 'customInput' }} />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput required size="lg"
                            radius="lg" variant="filled"
                            placeholder="IMC" {...form.getInputProps('imc')}
                            classNames={{ input: 'customInput' }} />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Select required placeholder="Groupe sanguin"
                            size="lg" radius="lg"
                            variant="filled" {...form.getInputProps('bloodType')}
                            classNames={{ input: 'customInput' }} data={bloodTypesData} />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput required size="lg"
                            radius="lg" variant="filled"
                            placeholder="Diagnostic" {...form.getInputProps('diagnostic')}
                            classNames={{ input: 'customInput' }} />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Group mt="xl">
                            <Button type='submit' fullWidth
                                size="lg" leftIcon={<Save />}
                                loading={store.saveInProgress}
                                radius="md">Save changes</Button>
                            <Button onClick={() => props.close()} fullWidth
                                size="lg" leftIcon={<Trash2 />}
                                color="yamnaGreen" radius="md">Discard changes</Button>
                        </Group>
                    </Grid.Col>
                </Grid>
            </ScrollArea>

        </form>

    )
}
