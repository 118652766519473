import { ActionIcon, Card, DefaultProps, Group, Stack, Text, Title } from '@mantine/core'
import moment from 'moment'
import { Edit2, Trash } from 'react-feather'
import Appointment from '../../../models/appointment'

interface AppointmentComponentProps {
    appointment: Appointment
    onEdit: () => void
    onDelete: () => void
}

export default function AppointmentComponent({
    appointment, onEdit, onDelete
}: AppointmentComponentProps & DefaultProps) {
    return (
        <Card
            radius={'lg'}
            key={appointment.id}
            style={{
                backgroundColor: '#F4F5F5'
            }}
        >
            <Stack>
                <Group position="apart">
                    <Title color="yamnaBlue">{appointment.number}</Title>
                    <Group>
                        <ActionIcon color="yamnaGreen"
                            radius="xl"
                            variant="filled"
                            onClick={onEdit}>
                            <Edit2 size={15} />
                        </ActionIcon>
                        <ActionIcon color="yamnaGreen"
                            radius="xl"
                            variant="filled"
                            onClick={onDelete}>
                            <Trash size={15} />
                        </ActionIcon>
                    </Group>
                </Group>

                <Text
                    color="yamnaBlue"
                    weight={600}>
                    {moment(appointment.appointmentAt).format('DD/MM/YYYY')}
                - ≈ {moment(appointment.appointmentAt).format('HH:mm')}
                </Text>

                <Text weight={600}>{appointment.patient?.fullname}</Text>
                <Text>{appointment.motive || 'No motive'}</Text>
                {
                    appointment.done ? (<Text color={'dimmed'}>Completed</Text>) : <></>
                }
            </Stack>
        </Card>

    )
}
