import { Button, Drawer, Grid, Group, LoadingOverlay, ScrollArea, Stack, Tabs, Text } from '@mantine/core'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { Edit2, Trash } from 'react-feather'
import Appointment from '../../../models/appointment'
import User, { WeekDays } from '../../../models/user'
import { useAppointmentsStore } from '../../../stores/appointmentsStore'
import DeleteAppointmentModalComponent from '../../Appointments/DeleteAppointment'
import EditAppointmentForm from '../../Appointments/EditAppointmentForm'
import AppointmentComponent from '../components/Appointment'
import { useStaffViewStore } from './staffViewStore'

interface ViewStaffDrawerProps {
    model: User | null
    close: () => void
    openEditDrawer: () => void
    openDeleteDrawer: () => void
}

export default function ViewStaffDrawer(props: ViewStaffDrawerProps) {
    const store = useStaffViewStore()
    const appointmentsStore = useAppointmentsStore()

    useEffect(() => {
        if (!props.model) return
        store.controls.fetchStaff(props.model.id)
    }, [store.controls, props.model])

    const user = useMemo(() => store.user, [store.user])
    const [currentDeletingAppointment, setCurrentDeletingAppointment] = useState<Appointment | null>(null)
    const [currentEditingAppointment, setCurrentEditingAppointment] = useState<Appointment | null>(null)

    return (<>
        <Drawer
            position='right'
            opened={props.model !== null}
            onClose={() => props.close()}
            padding='xl'
            size={'xl'}
            styles={{
                closeButton: {
                    display: 'none'
                }
            }}>
            <LoadingOverlay visible={store.isFetchPending}></LoadingOverlay>
            <div>
                <Text
                    weight={700}
                    color="yamnaGreen"
                    size='xl'
                >{user ? (`${user.namePrefix ?? ''} ${user.lastName ?? ''}`) : 'Loading...'}</Text>

                <Text
                    weight={600}
                    color={'dimmed'}
                    mt={20}
                >{user?.phoneNumber ?? 'Loading...'}</Text>
                <Text
                    weight={600}
                    color={'dimmed'}
                >{user?.email ?? 'Loading...'}</Text>

                <Tabs
                    mt={25}
                    defaultValue="details"
                >
                    <Tabs.List grow>
                        <Tabs.Tab value="details">Details</Tabs.Tab>
                        <Tabs.Tab value="appointments">Appointments</Tabs.Tab>
                        <Tabs.Tab value="availability">Availability</Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value='details' mt={'md'}>
                        <Grid grow gutter="md">
                            <Grid.Col span={12}>
                                <Group position="apart">
                                    <Text weight={600} color={'dimmed'}>Date d&apos;adhésion</Text>
                                    <Text weight={700} color="yamnaGreen">{
                                        user ? moment(user.createdAt).format('LL') : 'Loading ...'
                                    }</Text>
                                </Group>
                                <Group position="apart" mt={15}>
                                    <Text weight={600} color="gray">Rôle</Text>
                                    <Text weight={700} color="yamnaGreen">{
                                        user ? (user.role?.name ?? 'Not set') : 'Loading...'
                                    }</Text>
                                </Group>
                                <Group position="apart" mt={15}>
                                    <Text weight={600} color="gray">Spécialité</Text>
                                    <Text weight={700} color="yamnaGreen">{user?.speciality ?? 'Loading...'}</Text>
                                </Group>
                            </Grid.Col>
                            <Grid.Col span={12}>
                                <Button fullWidth leftIcon={<Edit2 />}
                                    size="lg" color="orange"
                                    className="rightIconFullWidth"
                                    variant='outline'
                                    onClick={props.openEditDrawer}
                                >Edit information</Button>
                            </Grid.Col>
                            <Grid.Col span={12}>
                                <Button fullWidth leftIcon={<Trash />}
                                    size="lg" color="red"
                                    className="rightIconFullWidth"
                                    variant='outline'
                                    onClick={props.openDeleteDrawer}
                                >Delete staff</Button>
                            </Grid.Col>
                        </Grid>
                    </Tabs.Panel>
                    <Tabs.Panel
                        value="appointments"
                        mt={'md'}
                        style={{
                            maxHeight: '70vh'
                        }}
                    >
                        <ScrollArea style={{ height: '70vh' }}>
                            <Stack
                                spacing={'xs'}
                                align='stretch'
                                style={{
                                    display: 'flex', // use flexbox
                                    flexDirection: 'column', // set flex direction to 'column'
                                    position: 'relative',
                                    flexGrow: 1 // make the container element expand to fill the available space
                                }}
                            >
                                {
                                    user?.appointments?.length === 0 && <Text align='center'>No Appointments</Text>
                                }
                                {
                                    user?.appointments?.map((appointment, index) => {
                                        return (
                                            <AppointmentComponent
                                                appointment={appointment}
                                                onDelete={() => setCurrentDeletingAppointment(appointment)}
                                                onEdit={() => setCurrentEditingAppointment(appointment)}
                                                key={index}
                                            ></AppointmentComponent>
                                        )
                                    }) ?? 'Loading ...'
                                }
                            </Stack>
                        </ScrollArea>
                    </Tabs.Panel>
                    <Tabs.Panel value="availability">
                        <Stack
                            align={'stretch'}
                            mt={'md'}
                            px={'sm'}
                        >
                            { user
                                ? Object.values(WeekDays).map((weekday) => {
                                    let content = <Text
                                        color="gray"
                                        align='end'
                                    >Day off</Text>

                                    if (user.schedule && weekday in user.schedule) {
                                        content = <Text align='end'>
                                            {user.schedule[weekday]?.start}
                                            -
                                            {user.schedule[weekday]?.end}
                                        </Text>
                                    }

                                    return (
                                        <Group
                                            spacing={'xs'}
                                            key={weekday}
                                            my={'xs'}
                                        >
                                            <Text
                                                fw={700}
                                                style={{
                                                    textTransform: 'capitalize',
                                                    flex: 2
                                                }}>{weekday}</Text>
                                            <Group grow style={{ flex: 4 }}>
                                                {content}
                                            </Group>
                                        </Group>
                                    )
                                })
                                : <LoadingOverlay visible></LoadingOverlay>
                            }
                        </Stack>
                    </Tabs.Panel>
                </Tabs>
            </div>
        </Drawer>

        <Drawer
            position='right'
            opened={currentEditingAppointment !== null}
            onClose={() => setCurrentEditingAppointment(null)}
            padding='xl'
            styles={{
                closeButton: {
                    display: 'none'
                }
            }}>
            { currentEditingAppointment &&
                    <EditAppointmentForm
                        closeModal={() => setCurrentEditingAppointment(null)}
                        appointment={currentEditingAppointment}
                    ></EditAppointmentForm>
            }
        </Drawer>

        <DeleteAppointmentModalComponent
            state={currentDeletingAppointment !== null}
            deletePending={appointmentsStore.isDeletePending}
            confirmDelete={() => {
                if (currentDeletingAppointment != null) {
                    void appointmentsStore.controls.deleteAppointment(currentDeletingAppointment.id)
                        .then(() => setCurrentDeletingAppointment(null))
                        .then(() => {
                            if (user != null) { store.controls.fetchStaff(user.id) }
                        })
                }
            }}
            cancelDelete={() => setCurrentDeletingAppointment(null)} />
    </>
    )
}

// function ViewStaffDrawer
// ({ drawerGet, drawerSet, staffGet, staffData, onStaffDelete, onStaffUpdate, onAppointmentDelete, onAppointmentEdit }) {
//     const [openedHistoryModal, setHistoryModal] = useState(false);
//     const [timerStatus, setTimerStatus] = useState(false);
//     const [timerValue, setTimerValue] = useState(0);
//     const [indexTimer, setIndexTimer] = useState(null);
//     const formAppointment = useForm({
//         initialValues: {
//             date: "",
//             doctorcId: 0,
//             patientId: 0,
//             hour: "",
//             motive: ""
//         },
//     });
//     const formHistory = useForm({
//         initialValues: {
//             note: "",
//             toPay: 0,
//             leftToPay: 0
//         },
//     });

//     const useStyle = createStyles(() => ({
//         root: {
//             width: "100%",
//             height: "100%",
//             display: "flex",
//             flexDirection: "column"
//         }
//     }));
//     const workHours = JSON.parse(staffData.hours);
//     const form = useForm({
//         initialValues: {
//             sundays: workHours?.sundays.availbilty,
//             sundaysStart: workHours?.sundays.start,
//             sundaysEnd: workHours?.sundays.end,
//             mondays: workHours?.mondays.availbilty,
//             mondaysStart: workHours?.mondays.start,
//             mondaysEnd: workHours?.mondays.end,
//             tuesdays: workHours?.tuesdays.availbilty,
//             tuesdaysStart: workHours?.tuesdays.start,
//             tuesdaysEnd: workHours?.tuesdays.end,
//             wednesdays: workHours?.wednesdays.availbilty,
//             wednesdaysStart: workHours?.wednesdays.start,
//             wednesdaysEnd: workHours?.wednesdays.end,
//             thursdays: workHours?.thursdays.availbilty,
//             thursdaysStart: workHours?.thursdays.start,
//             thursdaysEnd: workHours?.thursdays.end,
//             fridays: workHours?.fridays.availbilty,
//             fridaysStart: workHours?.fridays.start,
//             fridaysEnd: workHours?.fridays.end,
//             saturdays: workHours?.saturdays.availbilty,
//             saturdaysStart: workHours?.saturdays.start,
//             saturdaysEnd: workHours?.saturdays.end,
//         }
//     });
//     const { classes } = useStyle();

//     function getAge(dateString) {
//         var today = new Date();
//         var birthDate = new Date(dateString);
//         var age = today.getFullYear() - birthDate.getFullYear();
//         var m = today.getMonth() - birthDate.getMonth();
//         if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
//             age--;
//         }
//         return age;
//     }

//     function onTimerStop(parms) {
//         setTimerValue(parms.time);
//     }
//     function stopTimer() {
//         setTimerStatus(false);
//     }
//     function startTimer(appointment, index) {
//         formAppointment.setValues(appointment);
//         setIndexTimer(index);
//         setTimerStatus(true);
//     }

//     function endConsultation() {
//         setHistoryModal(true);
//     }
//     async function createHistoryForAppointment(params) {
//         await createHistory({ note: params.note, appointmentId: formAppointment.values.id, duration:
// moment(timerValue).format("mm:ss") }).then(async () => {
//             await updateAppointmentService({ done: true, dateHour: formAppointment.values.dateHour,
//  doctorcId: formAppointment.values.doctorcId, patientId: formAppointment.values.patientId },
//  formAppointment.values.id).then(async () => {
//                 await createPaymentService({ toPay: params.toPay, leftToPay: params.leftToPay,
// appointmentId: formAppointment.values.id }).then(async () => {
//                     staffGet();
//                     drawerSet(false);
//                     setHistoryModal(false);
//                     setIndexTimer(null);
//                     setTimerValue(0);
//                     formAppointment.reset();
//                     formHistory.reset();
//                 })
//             })
//         })
//     }
//     async function updateDoctorHours(params) {
//         const workingHours = {
//             sundays: { availbilty: params.sundays, start: params.sundaysStart, end: params.sundaysEnd },
//             mondays: { availbilty: params.mondays, start: params.mondaysStart, end: params.mondaysEnd },
//             tuesdays: { availbilty: params.tuesdays, start: params.tuesdaysStart, end: params.tuesdaysEnd },
//             wednesdays: { availbilty: params.wednesdays, start: params.wednesdaysStart, end: params.wednesdaysEnd },
//             thursdays: { availbilty: params.thursdays, start: params.thursdaysStart, end: params.thursdaysEnd },
//             fridays: { availbilty: params.fridays, start: params.fridaysStart, end: params.fridaysEnd },
//             saturdays: { availbilty: params.saturdays, start: params.saturdaysStart, end: params.saturdaysEnd },
//         }

//         await updateStaffService({ ...staffData, hours: JSON.stringify(workingHours) }, staffData.id).then((data) => {
//             drawerSet(false)
//             staffGet();
//             form.reset();
//         })
//     }
//     return (
//         <div>
//             <Drawer opened={drawerGet} onClose={() => drawerSet(false)} position="right"
// padding="xl" size="xl" overlayOpacity={0.55} overlayBlur={3} withCloseButton={true}
// closeOnClickOutside={false} closeOnEscape={false}>
//                 {
//                     <div className='containerShow'>
//                         <Text weight={700} color="yamnaGreen" size='xl'>Dr {staffData.firstName} {staffData.lastName}</Text>
//                         <Text weight={600} color="gray">{staffData.phoneNumber}</Text>
//                         <Text weight={600} color="gray">{staffData.email}</Text>
//                         <ScrollArea offsetScrollbars style={{ height: 750 }}>
//                             <Tabs tabPadding="xl" mt={25} grow >
//                                 <Tabs.Tab label="Disponibilité" disabled={!(staffData.role.name === "Doctor")}>
//                                     <form onSubmit={form.onSubmit((values) => updateDoctorHours(values))}>
//                                         <Grid grow gutter="lg" mt={40}>
//                                             <Grid.Col span={12}>
//                                                 <Group grow>
//                                                     <Grid gutter="xs">
//                                                         <Grid.Col span={3}><Text weight={700}>Dimanche</Text></Grid.Col>
//                                                         <Grid.Col span={2} my={2}><Switch {...form
// .getInputProps('sundays', { type: 'checkbox' })} /></Grid.Col>
//                                                         {form.getInputProps('sundays').value ?
//                                                             <>
//                                                                 <Grid.Col span={3}><Select
// {...form.getInputProps('sundaysStart')} defaultValue={'08:00'}
// data={startHoursArray} classNames={{ input: 'customSelectDate' }} variant="filled" radius="lg" size="xs" /></Grid.Col>
//                                                                 <Grid.Col span={1}>
/* <Text align="center" mx={8} my={2}>à</Text></Grid.Col> */
//                                                                 <Grid.Col span={3}>
/* <Select {...form.getInputProps('sundaysEnd')} defaultValue={'17:00'} data={endHoursArray} classNames=
{{ input: 'customSelectDate' }} variant="filled" radius="lg" size="xs" /></Grid.Col> */
//                                                             </> :
//                                                             <>
//
// <Grid.Col span={7}><Text p={2} color="gray">Jour de congé</Text></Grid.Col>
//                                                             </>
//                                                         }
//                                                     </Grid>
//                                                 </Group>
//                                             </Grid.Col>
//                                             <Grid.Col span={12}>
//                                                 <Group grow>
//                                                     <Grid gutter="xs">
//                                                         <Grid.Col span={3}><Text weight={700}>Lundi</Text></Grid.Col>
//                                                         <Grid.Col span={2} my={2}><Switch
// {...form.getInputProps('mondays', { type: 'checkbox' })} /></Grid.Col>
//                                                         {form.getInputProps('mondays').value ?
//                                                             <>
//                                                                 <Grid.Col span={3}><Select
// {...form.getInputProps('mondaysStart')} defaultValue={'08:00'} data={startHoursArray}
// classNames={{ input: 'customSelectDate' }} variant="filled" radius="lg" size="xs" /></Grid.Col>
//                                                                 <Grid.Col span={1}><Text
// align="center" mx={8} my={2}>à</Text></Grid.Col>
//                                                                 <Grid.Col span={3}><Select
// {...form.getInputProps('mondaysEnd')} defaultValue={'17:00'} data={endHoursArray}
// classNames={{ input: 'customSelectDate' }} variant="filled" radius="lg" size="xs" /></Grid.Col>
//                                                             </> :
//                                                             <>
//                                                                 <Grid.Col span={7}><Text
// p={2} color="gray">Jour de congé</Text></Grid.Col>
//                                                             </>
//                                                         }
//                                                     </Grid>
//                                                 </Group>
//                                             </Grid.Col>
//                                             <Grid.Col span={12}>
//                                                 <Group grow>
//                                                     <Grid gutter="xs">
//                                                         <Grid.Col span={3}><Text weight={700}>Mardi</Text></Grid.Col>
//                                                         <Grid.Col span={2} my={2}><Switch
// {...form.getInputProps('tuesdays', { type: 'checkbox' })} /></Grid.Col>
//                                                         {form.getInputProps('tuesdays').value ?
//                                                             <>
//                                                                 <Grid.Col span={3}><Select
// {...form.getInputProps('tuesdaysStart')} defaultValue={'08:00'} data={startHoursArray}
// classNames={{ input: 'customSelectDate' }} variant="filled" radius="lg" size="xs" /></Grid.Col>
//                                                                 <Grid.Col span={1}><Text
// align="center" mx={8} my={2}>à</Text></Grid.Col>
//                                                                 <Grid.Col span={3}><Select
// {...form.getInputProps('tuesdaysEnd')} defaultValue={'17:00'} data={endHoursArray}
// classNames={{ input: 'customSelectDate' }} variant="filled" radius="lg" size="xs" /></Grid.Col>

//                                                             </> :
//                                                             <>
//                                                                 <Grid.Col span={7}><Text
// p={2} color="gray">Jour de congé</Text></Grid.Col>
//                                                             </>
//                                                         }
//                                                     </Grid>
//                                                 </Group>
//                                             </Grid.Col>
//                                             <Grid.Col span={12}>
//                                                 <Group grow>
//                                                     <Grid gutter="xs">
//                                                         <Grid.Col span={3}><Text weight={700}>Mercredi</Text></Grid.Col>
//                                                         <Grid.Col span={2} my={2}><Switch
// {...form.getInputProps('wednesdays', { type: 'checkbox' })} /></Grid.Col>
//                                                         {form.getInputProps('wednesdays').value ?
//                                                             <>
//                                                                 <Grid.Col span={3}><Select
// {...form.getInputProps('wednesdaysStart')} defaultValue={'08:00'} data={startHoursArray}
// classNames={{ input: 'customSelectDate' }} variant="filled" radius="lg" size="xs" /></Grid.Col>
//                                                                 <Grid.Col span={1}><Text
// align="center" mx={8} my={2}>à</Text></Grid.Col>
//                                                                 <Grid.Col span={3}><Select
// {...form.getInputProps('wednesdaysEnd')} defaultValue={'17:00'} data={endHoursArray}
// classNames={{ input: 'customSelectDate' }} variant="filled" radius="lg" size="xs" /></Grid.Col>

//                                                             </> :
//                                                             <>
//                                                                 <Grid.Col span={7}><Text
// p={2} color="gray">Jour de congé</Text></Grid.Col>
//                                                             </>
//                                                         }
//                                                     </Grid>
//                                                 </Group>
//                                             </Grid.Col>
//                                             <Grid.Col span={12}>
//                                                 <Group grow>
//                                                     <Grid gutter="xs">
//                                                         <Grid.Col span={3}><Text weight={700}>Jeudi</Text></Grid.Col>
//                                                         <Grid.Col span={2} my={2}><Switch
// {...form.getInputProps('thursdays', { type: 'checkbox' })} /></Grid.Col>
//                                                         {form.getInputProps('thursdays').value ?
//                                                             <>
//                                                                 <Grid.Col span={3}><Select
// {...form.getInputProps('thursdaysStart')} defaultValue={'08:00'} data={startHoursArray}
// classNames={{ input: 'customSelectDate' }} variant="filled" radius="lg" size="xs" /></Grid.Col>
//                                                                 <Grid.Col span={1}><Text
// align="center" mx={8} my={2}>à</Text></Grid.Col>
//                                                                 <Grid.Col span={3}><Select
// {...form.getInputProps('thursdaysEnd')} defaultValue={'17:00'} data={endHoursArray}
// classNames={{ input: 'customSelectDate' }} variant="filled" radius="lg" size="xs" /></Grid.Col>

//                                                             </> :
//                                                             <>
//                                                                 <Grid.Col span={7}><Text
// p={2} color="gray">Jour de congé</Text></Grid.Col>
//                                                             </>
//                                                         }
//                                                     </Grid>
//                                                 </Group>
//                                             </Grid.Col>
//                                             <Grid.Col span={12}>
//                                                 <Group grow>
//                                                     <Grid gutter="xs">
//                                                         <Grid.Col span={3}><Text weight={700}>Vendredi</Text></Grid.Col>
//                                                         <Grid.Col span={2} my={2}><Switch
// {...form.getInputProps('fridays', { type: 'checkbox' })} /></Grid.Col>
//                                                         {form.getInputProps('fridays').value ?
//                                                             <>
//                                                                 <Grid.Col span={3}><Select
// {...form.getInputProps('fridaysStart')} defaultValue={'08:00'} data={startHoursArray}
// classNames={{ input: 'customSelectDate' }} variant="filled" radius="lg" size="xs" /></Grid.Col>
//                                                                 <Grid.Col span={1}><Text
// align="center" mx={8} my={2}>à</Text></Grid.Col>
//                                                                 <Grid.Col span={3}><Select
// {...form.getInputProps('fridaysEnd')} defaultValue={'17:00'} data={endHoursArray}
// classNames={{ input: 'customSelectDate' }} variant="filled" radius="lg" size="xs" /></Grid.Col>

//                                                             </> :
//                                                             <>
//                                                                 <Grid.Col span={7}><Text p={2}
// color="gray">Jour de congé</Text></Grid.Col>
//                                                             </>
//                                                         }
//                                                     </Grid>
//                                                 </Group>
//                                             </Grid.Col>
//                                             <Grid.Col span={12}>
//                                                 <Group grow>
//                                                     <Grid gutter="xs">
//                                                         <Grid.Col span={3}><Text weight={700}>Samedi</Text></Grid.Col>
//                                                         <Grid.Col span={2} my={2}><Switch
// {...form.getInputProps('saturdays', { type: 'checkbox' })} /></Grid.Col>
//                                                         {form.getInputProps('saturdays').value ?
//                                                             <>
//                                                                 <Grid.Col span={3}><Select
// {...form.getInputProps('saturdaysStart')} defaultValue={'08:00'} data={startHoursArray}
// classNames={{ input: 'customSelectDate' }} variant="filled" radius="lg" size="xs" /></Grid.Col>
//                                                                 <Grid.Col span={1}><Text
// align="center" mx={8} my={2}>à</Text></Grid.Col>
//                                                                 <Grid.Col span={3}><Select
// {...form.getInputProps('saturdaysEnd')} defaultValue={'17:00'} data={endHoursArray}
// classNames={{ input: 'customSelectDate' }} variant="filled" radius="lg" size="xs" /></Grid.Col>

//                                                             </> :
//                                                             <>
//                                                                 <Grid.Col span={7}><Text p={2}
// color="gray">Jour de congé</Text></Grid.Col>
//                                                             </>
//                                                         }
//                                                     </Grid>
//                                                 </Group>
//                                             </Grid.Col>

//                                             <Grid.Col span={12}>
//                                                 <Group mt="xl">
//                                                     <Button type="submit" fullWidth size="lg"
// leftIcon={<Save />} radius="md">Sauvegarder les modifications</Button>
//                                                 </Group>
//                                             </Grid.Col>
//                                         </Grid>
//                                     </form>
//                                 </Tabs.Tab>
//                             </Tabs>
//                         </ScrollArea>
//                     </div>
//                 }
//             </Drawer>

//             <Modal withCloseButton={false} closeOnClickOutside={false} closeOnEscape={false}
// centered overlayOpacity={0.55} overlayBlur={3} opened={openedHistoryModal} >
//                 {
//                     <Grid grow gutter="xs">
//                         <Grid.Col span={12}>
//                             <h3>Ajouter les informations de rendez-vous</h3>
//                         </Grid.Col>
//                         <form onSubmit={formHistory.onSubmit((values) => createHistoryForAppointment(values))}
// className={classes.root}>
//                             <Grid.Col span={12}>
//                                 <Textarea size="lg" radius="lg"
// variant="filled" placeholder="Note" {...formHistory.getInputProps('note')} classNames={{ input: 'customInput' }} />
//                             </Grid.Col>
//                             <Grid.Col span={12}>
//                                 <TextInput size="lg" radius="lg" variant="filled"
// label="À Payer" placeholder="À Payer" {...formHistory.getInputProps('toPay')} classNames={{ input: 'customInput' }} />
//                             </Grid.Col>
//                             <Grid.Col span={12}>
//                                 <TextInput size="lg" radius="lg" variant="filled"
// label="Reste à payer" placeholder="Reste à payer"
// {...formHistory.getInputProps('leftToPay')} classNames={{ input: 'customInput' }} />
//                             </Grid.Col>
//                             <Grid.Col span={12}>
//                                 <Group>
//                                     <Button type="submit"
// fullWidth size="lg" color="yamnaBlue" radius="md">Confirmer</Button>
//                                 </Group>
//                             </Grid.Col>
//                         </form>
//                     </Grid>
//                 }
//             </Modal>
//         </div>
//     );
// }

// export default ViewStaffDrawer;
