import { AxiosResponse } from 'axios'
import create from 'zustand'
import axiosClient from '../../api/ApiClient'
import Appointment from '../../models/appointment'
import { Medicine } from '../../models/medicine'

interface AppointmentsByPatientParams {
    patientId: number
    day: string
}

interface AppointmentWorkflowStore {
  isFetchPending: boolean
  appointments: Appointment[]
  workflowProcessPending: boolean
  currentWorkflowStep: 'none' | 'notes' | 'medicines' | 'payment'
  controls: {
    fetchPatientsAppointmentsForDay: (filter: AppointmentsByPatientParams) => Promise<void>
    cancel: () => void
    startWorkflow: () => void
    setNote: (id: number, note: string) => Promise<void>
    setMedicines: (id: number, value: Medicine[]) => Promise<void>
    setPaymentInfo: (id: number, toPay: number, paid: number, notifyAssistant: boolean) => Promise<void>
  }
}

export type { AppointmentWorkflowStore }

const useAppointmentWorkflowStore = create<AppointmentWorkflowStore>((set, get) => ({
    appointments: [],
    isFetchPending: false,
    isCreatePending: false,
    workflowProcessPending: false,
    currentWorkflowStep: 'none',
    controls: {
        fetchPatientsAppointmentsForDay: async (filter: AppointmentsByPatientParams) => {
            set(() => ({
                isFetchPending: true
            }))

            const params = new URLSearchParams()
            params.set('patientId', filter.patientId.toString())
            params.set('day', filter.day)

            return await axiosClient.get('/appointment/by-patient', { params }).then(response => response.data)
                .then(appointmentsList => {
                    set(() => ({
                        appointments: appointmentsList
                    }))
                }).finally(() => {
                    set(() => ({
                        isFetchPending: false
                    }))
                })
        },
        cancel: () => {
            set({
                currentWorkflowStep: 'none'
            })
        },
        startWorkflow: () => {
            set({
                currentWorkflowStep: 'notes'
            })
        },
        setNote: async (id, note) => {
            set({
                workflowProcessPending: true
            })
            await axiosClient.post('/appointment/' + id.toString() + '/notes', {
                note
            }).then(() => {
                set({
                    currentWorkflowStep: 'medicines'
                })
            }).finally(() => set({
                workflowProcessPending: false
            }))
        },
        setMedicines: async (id, medicines) => {
            set({
                workflowProcessPending: true
            })
            await axiosClient.post('/appointment/' + id.toString() + '/medicines', {
                medicines
            }).then(() => {
                set({
                    currentWorkflowStep: 'payment'
                })
            }).finally(() => set({
                workflowProcessPending: false
            }))
        },
        setPaymentInfo: async (id, toPay, paid, notifyAssistant) => {
            set({
                workflowProcessPending: true
            })
            await axiosClient.post('/appointment/' + id.toString() + '/payment', {
                paid,
                toPay,
                notifyAssistant
            }).then((doneAppointment: AxiosResponse<Appointment>) => {
                set({
                    currentWorkflowStep: 'none',
                    appointments: get().appointments
                        .filter(appointment => appointment.id !== doneAppointment.data.id)
                        .concat(doneAppointment.data)
                })
            }).finally(() => set({
                workflowProcessPending: false
            }))
        }
    }
}))

export { useAppointmentWorkflowStore }
