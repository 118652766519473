import { Card, ScrollArea, Tabs, Text } from '@mantine/core'
import ProfileDetailsForm from './ProfileDetailsForm'

export default function Profile() {
    return (
        <>
            <Card
                p={32}
                m={'xl'}
                radius={'lg'}
                style={{ width: 'auto', height: '100%' }}
            >
                <Text
                    fw={700}
                    fz={24}
                    mb={24}
                >Profile</Text>

                <Tabs
                    unstyled
                    defaultValue="details"
                    styles={(theme) => ({
                        root: {
                            height: '100%'
                        },
                        tab: {
                            fontWeight: 600,
                            lineHeight: '24px',
                            fontSize: '16px',
                            color: '#719BBC',
                            cursor: 'pointer',
                            backgroundColor: 'transparent',
                            border: 'none',
                            paddingBottom: '6px',

                            '&:disabled': {
                                opacity: 0.5,
                                cursor: 'not-allowed'
                            },

                            '&[data-active]': {
                                color: theme.colors.blue[4],
                                borderBottomColor: theme.colors.blue[4],
                                borderBottom: '2px solid'
                            }
                        },
                        tabsList: {
                            display: 'flex',
                            gap: '24px'
                        }
                    })}
                >
                    <Tabs.List mb={'xl'}>
                        <Tabs.Tab value="details">Details</Tabs.Tab>
                        <Tabs.Tab value="services">Services</Tabs.Tab>
                        <Tabs.Tab value="displomas">Diplomas and certificates</Tabs.Tab>
                        <Tabs.Tab value="availability">Availability</Tabs.Tab>
                        <Tabs.Tab value="motives">Motifs</Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel
                        value='details'
                        h={'80%'}
                    >
                        <ScrollArea h={'100%'}>
                            <ProfileDetailsForm />
                        </ScrollArea>
                    </Tabs.Panel>
                </Tabs>
            </Card>
        </>
    )
}
