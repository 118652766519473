import { Button, Modal, NumberInput, Stack, Switch, Text } from '@mantine/core'
import { useForm } from '@mantine/form'
import { FaCheck, FaTimes } from 'react-icons/fa'

interface PaymentStepModalProps {
    appointmentId: number
    modalState: boolean
    cancel: () => void
    continueWithResult: (payment: PaymentForm) => void
}

interface PaymentForm {
    toPay: number
    paid: number
    notifyAssistant: boolean
}

export default function PaymentStepModal(props: PaymentStepModalProps) {
    function close() {
        props.cancel()
        form.reset()
    }

    const form = useForm<PaymentForm>({
        initialValues: {
            toPay: 0,
            paid: 0,
            notifyAssistant: true
        }
    })

    return (
        <Modal
            withCloseButton={false}
            centered
            overlayOpacity={0.55} overlayBlur={3}
            opened={props.modalState}
            onClose={() => {
                close()
            }}
            padding={'xl'}
            size='lg'
            radius={'lg'}
        >
            <Stack align={'stretch'} spacing={'xl'}>
                <Text size='xl' weight={600}
                    align={'center'}>Add payment information</Text>

                <NumberInput
                    radius={'lg'}
                    size='lg'
                    type='text'
                    variant='filled'
                    hideControls
                    label='To pay'
                    placeholder='To pay'
                    {...form.getInputProps('toPay')}>
                </NumberInput>

                <NumberInput
                    radius={'lg'}
                    size='lg'
                    type='text'
                    variant='filled'
                    label='Paid'
                    placeholder='Paid'
                    hideControls
                    {...form.getInputProps('paid')}>
                </NumberInput>

                <Switch
                    labelPosition='left'
                    label="Notify assistant"
                    {...form.getInputProps('notifyAssistant', {
                        type: 'checkbox'
                    })}
                    size='md'
                    mb={'md'}
                    thumbIcon={
                        form.values.notifyAssistant
                            ? (<FaCheck color='#000'></FaCheck>)
                            : (<FaTimes></FaTimes>)
                    }
                    styles={{
                        label: {
                            fontWeight: 600
                        }
                    }}
                >
                </Switch>

                <Button
                    onClick={() => {
                        props.continueWithResult(form.values)
                        close()
                    }}
                    fullWidth
                    size='lg'
                    color='yamnaGreen'
                    radius={'md'}
                >
                    Done
                </Button>
            </Stack>
        </Modal>
    )
}
