import create from 'zustand'
import axiosClient from '../../api/ApiClient'
import Clinic from '../../models/clinic'

interface WebpageStore {
  currentClinic: Clinic | null
  fetchPending: boolean
  controls: {
    fetchCurrentClinic: () => void
  }
}

const useWebpageStore = create<WebpageStore>((set) => ({
    currentClinic: null,
    fetchPending: false,
    controls: {
        fetchCurrentClinic: () => {
            set({
                fetchPending: true
            })
            void axiosClient.get('/clinic/current').then(response => response.data).then(clinic => {
                set({
                    currentClinic: clinic,
                    fetchPending: false
                })
            }).finally(() => {
                set({
                    fetchPending: false
                })
            })
        }
    }
}))

export { useWebpageStore }
