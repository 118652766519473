import create from 'zustand'
import axiosClient from '../../../api/ApiClient'
import Patient from '../../../models/patient'

interface PatientViewStore {
  patient: Patient | null
  isFetchPending: boolean
  controls: {
    fetchPatient: (
        id: Patient['id'],
    ) => void
  }
}

const usePatientViewStore = create<PatientViewStore>((set) => ({
    patient: null,
    isFetchPending: false,
    controls: {
        fetchPatient: (id) => {
            set(() => ({
                isFetchPending: true
            }))

            void axiosClient.get(`/patient/${id}`).then(response => response.data).then(patient => {
                set(() => ({
                    patient,
                    isFetchPending: false
                }))
            })
        }
    }
}))

export { usePatientViewStore }
