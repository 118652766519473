import { AxiosResponse } from 'axios'
import create from 'zustand'
import axiosClient from '../../../api/ApiClient'
import Role from '../../../models/role'
import User, { Schedule } from '../../../models/user'

export interface CreateStaffReqDto {
    firstName: string
    lastName: string
    email: string
    password: string
    phoneNumber: string
    roleId: number
    speciality: string
    permissions: string[]
    schedule: Schedule
}

interface AddStaffStore {
  roles: Role[]
  saveInProgress: boolean
  rolesFetching: boolean
  controls: {
    addStaff: (
        data: CreateStaffReqDto
    ) => Promise<void>
    fetchRoles: () => Promise<void>
  }
}

const useAddStaffStore = create<AddStaffStore>((set) => ({
    saveInProgress: false,
    rolesFetching: false,
    roles: [],
    controls: {
        addStaff: async (
            data
        ) => {
            set({ saveInProgress: true })

            await axiosClient.post('/user', data).then((response: AxiosResponse<User>) => response.data)
                .finally(() => set({ saveInProgress: false }))
        },
        fetchRoles: async () => {
            set({ rolesFetching: false })

            await axiosClient.get('/role').then((response: AxiosResponse<Role[]>) => response.data)
                .then((roles) => set({ roles }))
                .finally(() => set({ rolesFetching: false }))
        }
    }
}))

export { useAddStaffStore }
