import { AxiosResponse } from 'axios'
import create from 'zustand'
import axiosClient from '../../../api/ApiClient'
import User from '../../../models/user'

interface DeleteStaffStore {
  status: 'pending' | 'completed' | 'error' | 'default'
  controls: {
    deleteStaff: (id: number) => Promise<void>
  }
}

const useDeleteStaffStore = create<DeleteStaffStore>((set) => ({
    status: 'default',
    controls: {
        deleteStaff: async (id) => {
            set({ status: 'pending' })

            await axiosClient.delete(`/user/${id}`).then((response: AxiosResponse<User>) => response.data)
                .then(() => set({ status: 'completed' })).catch(() => set({ status: 'error' }))
        }
    }
}))

export { useDeleteStaffStore }
