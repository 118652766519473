import { ActionIcon, Button, Card, Group, LoadingOverlay, Stack, Table, Text } from '@mantine/core'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Edit2, Eye, PlusSquare, Trash } from 'react-feather'
import User from '../../models/user'
import AddStaffDrawer from './add/AddStaff'
import DeleteStaff from './delete/DeleteStaff'
import EditStaffDrawer from './edit/EditStaff'
import { useStaffStore } from './staffStore'
import ViewStaffDrawer from './view/ViewStaff'

export default function Staff() {
    const staffStore = useStaffStore()

    const [isAddDrawerOpen, setDrawerAddOpened] = useState(false)
    const [currentEditingStaff, setCurrentEditingStaff] = useState<User | null>(null)
    const [currentDeletingStaff, setCurrentDeletingStaff] = useState<User | null>(null)
    const [currentViewingStaff, setCurrentViewingStaff] = useState<User | null>(null)

    useEffect(() => {
        if (isAddDrawerOpen) return

        if (currentDeletingStaff !== null) return

        if (currentEditingStaff !== null) return

        staffStore.controls.searchStaff({ query: '' })
    }, [staffStore.controls, isAddDrawerOpen, currentDeletingStaff, currentEditingStaff])

    function formatDate(dateString: string) {
        return moment(dateString).calendar()
    }

    return <div>
        <LoadingOverlay visible={staffStore.isFindPending} ></LoadingOverlay>
        <Card
            radius={'lg'}
            shadow={'md'}
            mx={'md'}
            px={'xl'}
        >
            <Stack>
                <Group position="apart">
                    <h2>List de Staff</h2>
                    <Button leftIcon={<PlusSquare />}
                        size="lg"
                        onClick={() => setDrawerAddOpened(true)}
                        pr={128}
                        radius={'md'}
                    >Add new staff member
                    </Button>
                </Group>
                <Table striped highlightOnHover
                    horizontalSpacing="xl" verticalSpacing="md"
                    className="cusTomTable">
                    <thead>
                        <tr>
                            <th>Nom et prénom</th>
                            <th>Date d&apos;adhésion</th>
                            <th>Role</th>
                            <th>Speciality</th>
                            <th>Availability</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            staffStore.users.map((user) => {
                                return (
                                    <tr key={user.id} >
                                        <td><Text weight={600}>{user.firstName} {user.lastName}</Text></td>
                                        <td>{formatDate(user.createdAt)}</td>
                                        <td>Doctor</td>
                                        <td>{user.speciality}</td>
                                        <td>{user.isVerified ? 'Disponsible' : 'Not verified'}</td>
                                        <td>
                                            <Group>
                                                <ActionIcon color="yamnaGreen" radius="xl"
                                                    variant="filled"
                                                    onClick={() => {
                                                        setCurrentViewingStaff(user)
                                                    }}
                                                >
                                                    <Eye size={15} />
                                                </ActionIcon>
                                                <ActionIcon color="yamnaGreen" radius="xl"
                                                    variant="filled" onClick={() => {
                                                        setCurrentEditingStaff(user)
                                                    }}>
                                                    <Edit2 size={15} />
                                                </ActionIcon>
                                                <ActionIcon color="yamnaGreen" radius="xl"
                                                    variant="filled" onClick={() => {
                                                        setCurrentDeletingStaff(user)
                                                    }}>
                                                    <Trash size={15} />
                                                </ActionIcon>
                                            </Group>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </Stack>
        </Card>

        <AddStaffDrawer state={isAddDrawerOpen} close={ () => setDrawerAddOpened(false) }></AddStaffDrawer>
        <EditStaffDrawer close={() => setCurrentEditingStaff(null)} editModel={currentEditingStaff}></EditStaffDrawer>
        <ViewStaffDrawer
            close={() => setCurrentViewingStaff(null)}
            model={currentViewingStaff}
            openDeleteDrawer={() => {
                setCurrentDeletingStaff(currentViewingStaff)
                setCurrentViewingStaff(null)
            }}
            openEditDrawer={() => {
                setCurrentEditingStaff(currentViewingStaff)
                setCurrentViewingStaff(null)
            }}
        ></ViewStaffDrawer>
        {/*
        <Drawer
            position='right'
            opened={currentViewingPatient != null}
            onClose={() => setCurrentViewingPatient(null)}
            padding='xl'
            size={'xl'}
            styles={{
                closeButton: {
                    display: 'none'
                }
            }}>
            { currentViewingPatient
                ? <ShowPatientDrawerContent
                    patient={currentViewingPatient}
                    editPatient={() => {
                        setCurrentEditingPatient(currentViewingPatient)
                        setCurrentViewingPatient(null)
                    }}
                    deletePatient={() => {
                        setCurrentDeletingPatient(currentViewingPatient)
                        setCurrentViewingPatient(null)
                    }}
                ></ShowPatientDrawerContent>
                : <></>
            }
        </Drawer>
*/}

        <DeleteStaff staff={currentDeletingStaff} close={() => setCurrentDeletingStaff(null)}></DeleteStaff>

    </div>
}
// const [openDrawerUpdate, setDrawerUpdate] = useState(false);
// const [openedDrawerUpdateAppointments, setUpdateDrawerUpdateAppointments] = useState(false);
// const [openedModalDeleteAppointments, setModalDeleteAppointments] = useState(false);
// const [openShowDrawer, setShowDrawer] = useState(false);
// const [selectedStaff, setSelectedStaff] = useState([])
// function editStaff(s) {
//     setSelectedStaff(s);
//     setDrawerUpdate(true);
// }

// function openShowStaffDrawer(staff) {
//     setSelectedStaff(staff);
//     setShowDrawer(true);
// }
// async function openUpdateAppointmentDrawer(appointment) {
//     console.log(appointment);
//     setShowDrawer(false);
//     setUpdateDrawerUpdateAppointments(true);
//     formAppointment.setValues({ dateHour: appointment.dateHour, id: appointment.id, patientId:
//  appointment.patientId, motive: appointment.motive, doctorcId: appointment.doctorcId,
//  date: moment(appointment.dateHour).toDate(), hour: moment(appointment.dateHour).format("HH:mm") })
//     form.setValues({ ...appointment.patient, birthday: new Date(appointment.patient.birthday) })
// }
// return (
//     <div>
//         <Grid className='patients' grow mt={30}>
//             <Grid.Col span={12}>
//                 <div className="box">
//                     <Grid grow>
//                         <Grid.Col span={12}>
//                             <Group position="apart">
//                                 <h2>List de personnel</h2>
//                             </Group>
//                         </Grid.Col>
//                         <Grid.Col>
//                             <Table striped highlightOnHover horizontalSpacing="xl"
// verticalSpacing="md" className="cusTomTable">
//                                 <thead>
//                                     <tr>
//                                         <th>Nom et prénom</th>
//                                         <th>Date d'adhésion</th>
//                                         <th>Rôle</th>
//                                         <th>Spécialité</th>
//                                         <th>Disponibilité</th>
//                                         <th>Action</th>
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     {
//                                         staffData.map((p, index) => {
//                                             return (
//                                                 < tr key={p.id} >
//                                                     <td>{p.firstName} {p.lastName}</td>
//                                                     <td>{moment(p.createdAt).format("DD/MM/YYYY")}</td>
//                                                     <td>{p.role.name}</td>
//                                                     <td>{p.speciality?.name}</td>
//                                                     <td>{p.availablity}</td>
//                                                     <td>
//                                                         <Group>
//                                                             <ActionIcon color="yamnaGreen"
// radius="xl" variant="filled" onClick={() => openShowStaffDrawer(p)}>
//                                                                 <Eye size={15} />
//                                                             </ActionIcon>
//                                                             <ActionIcon color="yamnaGreen"
// radius="xl" variant="filled" onClick={() => editStaff(p)}>
//                                                                 <Edit2 size={15} />
//                                                             </ActionIcon>
//                                                         </Group>
//                                                     </td>
//                                                 </tr>
//                                             )
//                                         })
//                                     }
//                                 </tbody>
//                             </Table>
//                         </Grid.Col>
//                     </Grid>
//                 </div>

//             </Grid.Col>
//         </Grid >

//         {
//             openedDrawerAdd ?
//                 <>
//                     <AddSttaf drawerGet={openedDrawerAdd} drawerSet={setDrawerAdd} staffGet={loadstaffData}></AddSttaf>
//                 </> : null
//         }

//         {
//             openDrawerUpdate ?
//                 <>
//                     <UpdateStaff drawerGet={openDrawerUpdate} drawerSet={setDrawerUpdate}
//  staffGet={loadstaffData} staffData={selectedStaff} drawerShowStaff={setShowDrawer}></UpdateStaff>
//                 </> : null
//         }

//         {
//             openModalDelete ?
//                 <>
// <DeleteStaff modalGet={openModalDelete} modalSet={setOpenModalDelete}
//  staffGet={loadstaffData} staffData={selectedStaff}></DeleteStaff>
//                 </> : null
//         }

//         {
//             openShowDrawer ?
//                 <>
//                     <ShowStaffComponents drawerGet={openShowDrawer} drawerSet={setShowDrawer}
//  staffGet={loadstaffData} staffData={selectedStaff} onStaffDelete={openDeleteModal}
//  onStaffUpdate={editStaff} onAppointmentDelete={openDeleteAppointmentModal}
// onAppointmentEdit={openUpdateAppointmentDrawer}></ShowStaffComponents>
//                 </> : null
//         }

//         {
//             openedDrawerUpdateAppointments ?
//                 <>
//                     <UpdateAppointmentComponents drawerGet={openedDrawerUpdateAppointments}
// drawerSet={setUpdateDrawerUpdateAppointments} remoteDrawerSet={setShowDrawer}
// patientsGet={loadstaffData} data={{ patient: form, appointment: formAppointment }}></UpdateAppointmentComponents>
//                 </> : null
//         }
//         {
//             openedModalDeleteAppointments ?
//                 <>
//                     <DeleteAppointmentModalComponent
// modalGet={openedModalDeleteAppointments} modalSet={setModalDeleteAppointments}
// remoteDrawerSet={setShowDrawer} patientsGet={loadstaffData} data={formAppointment}></DeleteAppointmentModalComponent>
//                 </> : null
//         }
//     </div>
// );
