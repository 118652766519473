import { createStyles } from '@mantine/core'
import moment from 'moment'

interface CalendarDayProps {
    day: string
    highlightToday?: boolean
    disabled?: boolean
    onClick?: (day: string) => void
}

const useStyles = createStyles((theme) => ({
    calendarDay: {
        border: '1px solid black',
        borderRadius: '50%',
        color: '#033446',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        margin: '2px',
        boxSizing: 'border-box',
        height: '40px',
        width: '40px'
    },
    todayDay: {
        backgroundColor: '#F18294',
        borderColor: '#F18294',
        color: theme.white
    },
    disabledDay: {
        opacity: 0.35
    }
}))

export default function CalendarDay({
    day,
    disabled = false,
    highlightToday = true,
    onClick = undefined
}: CalendarDayProps) {
    const { classes } = useStyles()
    const currentDayMoment = moment(day)

    let className = classes.calendarDay
    if (highlightToday && moment().isSame(currentDayMoment, 'day')) className += ' ' + classes.todayDay
    if (disabled) className += ' ' + classes.disabledDay

    return <div
        className={className}
        onClick={() => { if (onClick) onClick(day) }}
    >
        <span style={{ margin: 'auto' }}>{currentDayMoment.format('D')}</span>
    </div>
}
