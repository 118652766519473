import { Center } from '@mantine/core'
import { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuthStore } from '../stores/authStore'

interface PrivateRouteProps {
    children: ReactNode
    permission: string
}

function PrivateRoute({ children, permission }: PrivateRouteProps) {
    const { user, loggedIn } = useAuthStore()
    const permissions = user?.permissions ?? []
    const ownerOfCompany = !!user?.owen

    if (loggedIn) {
        if (ownerOfCompany || permissions.includes(permission)) {
            return <>{children}</>
        } else {
            return <Center> Permission required </Center>
        }
    } else {
        console.log('Not authenticated', user, loggedIn)
        return <Navigate to="/login" />
    }
}

export default PrivateRoute
