import { Button, Modal, Stack } from '@mantine/core'

interface DeleteAppointmentModalProps {
    confirmDelete: () => void
    cancelDelete: () => void
    state: boolean
    deletePending: boolean
}

export default function DeleteAppointmentModalComponent(
    { state, confirmDelete, cancelDelete, deletePending }: DeleteAppointmentModalProps
) {
    return (
        <Modal
            withCloseButton={false}
            closeOnClickOutside={false}
            closeOnEscape={false} centered
            overlayOpacity={0.55} overlayBlur={3}
            onClose={cancelDelete}
            opened={state}
            padding={'xl'}
            radius={'lg'}>

            <Stack
                align={'stretch'}
                spacing={'xl'}
                mx={'xl'}
                style={{ textAlign: 'center' }}>

                <h3>Are you sure you want to delete this Appointment?</h3>
                <p>By deleting this Appointments, you will lose all data regarding this appointment.</p>

                <Button
                    onClick={confirmDelete}
                    loading={deletePending}
                    fullWidth
                    size="lg" color="red"
                    radius="md">Confirm delete</Button>
                <Button onClick={cancelDelete} fullWidth
                    size="lg" color="yamnaGreen"
                    radius="md">Cancel</Button>

            </Stack>
        </Modal>
    )
}
