import { AppShell, MantineProvider } from '@mantine/core'
import { useEffect, useMemo } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Footer from './components/Footer/Footer'
import Header from './components/Header/Header'
import Appointments from './pages/Appointments/Appointments'
import Login from './pages/Login'
import Patients from './pages/Patients/Patients'
import Payments from './pages/Payments/Payments'
import Profile from './pages/Profile/Profile'
import Signup from './pages/Signup/Signup'
import Staff from './pages/Staff/Staff'
import Statistiques from './pages/Statistiques/Statistiques'
import WebPage from './pages/WebPage/WebPage'
import { useAuthStore } from './stores/authStore'
import PrivateRoute from './utils/PrivateRoute'

function App() {
    const { loggedIn, controls, user } = useAuthStore()

    useEffect(() => {
        void controls.loadCurrentUser(true)

        console.log('Backend API is', process.env.REACT_APP_API_URL)
    }, [controls])

    const signupCompleted = useMemo(() => user?.firstName !== '' && user?.lastName !== '' && user?.clinicId !== null, [user])

    return <AppContent isLoggedIn={loggedIn} signupCompleted={signupCompleted}></AppContent>
}

function LoginRoutes() {
    return (
        <Routes>
            <Route path="/signup"
                element={<Signup />} />,
            <Route path="/login"
                element={<Login />} />,
            <Route path="/"
                element={<Login />} />
        </Routes>
    )
}

function AppContent(props: { isLoggedIn: boolean, signupCompleted: boolean }) {
    let content
    if (props.isLoggedIn && props.signupCompleted) {
        content = <>
            <LoginRoutes />
            <AppShell
                header={<Header></Header>}
                footer={<Footer></Footer>}
                fixed={false}
                styles={{
                    root: {
                        overflow: 'hidden',
                        maxHeight: '100vh'
                    },
                    main: {
                        height: '65vh'
                    }
                }}
            >
                <Routes>
                    <Route path="/statistics"
                        element={
                            <PrivateRoute permission="statistics">
                                <Statistiques />
                            </PrivateRoute>
                        } />
                    <Route path="/patients"
                        element={
                            <PrivateRoute permission="patients">
                                <Patients />
                            </PrivateRoute>
                        } />
                    <Route path="/appointments"
                        element={
                            <PrivateRoute permission="appointments">
                                <Appointments />
                            </PrivateRoute>
                        } />
                    <Route path="/payments"
                        element={
                            <PrivateRoute permission="payments">
                                <Payments />
                            </PrivateRoute>
                        } />
                    <Route path="/staff"
                        element={
                            <PrivateRoute permission="staff">
                                <Staff />
                            </PrivateRoute>
                        } />
                    <Route path="/profile"
                        element={
                            <Profile />
                        } />
                    <Route path="/webpage"
                        element={
                            <WebPage />
                        } />
                    <Route path="/"
                        element={<Navigate to={'/appointments'} />} />
                </Routes>
            </AppShell>
        </>
    } else {
        content = <LoginRoutes />
    }
    return (
        <BrowserRouter>
            <MantineProvider
                withGlobalStyles
                withNormalizeCSS
                theme={{
                    colors: {
                        white: ['#FFFFFF', '#E6E6E6', '#CFCFCF', '#BABABA', '#A7A7A7', '#979797', '#888888'],
                        yamnaPink: [
                            '#FAF3F4',
                            '#F0D1D6',
                            '#EDABB6',
                            '#F18294',
                            '#FF526F',
                            '#EA4561',
                            '#D43D56',
                            '#BA3C51',
                            '#9C4150',
                            '#84434E'
                        ],
                        yamnaBlue: [
                            '#EFF3F5',
                            '#D1E1E6',
                            '#B3D1DD',
                            '#93C6D8',
                            '#70BEDA',
                            '#49BAE4',
                            '#1BBBF6',
                            '#20A5D6',
                            '#2D8DB0',
                            '#347993'
                        ],
                        yamnaGreen: [
                            '#314850',
                            '#29434C',
                            '#213F4A',
                            '#1A3B47',
                            '#123846',
                            '#0B3646',
                            '#033446',
                            '#092C39',
                            '#0C262F',
                            '#0E2027'
                        ]
                    },
                    primaryColor: 'yamnaBlue',
                    fontFamily: 'Poppins'
                }}
            >
                {content}
            </MantineProvider>
        </BrowserRouter>
    )
}

export default App
export { AppContent }
