import { Divider, Group, Paper, Stack, Text, Title, useMantineTheme } from '@mantine/core'
import moment from 'moment'
import Model from '../../../models/appointment'

interface StatisticsAppointmentComponentProps {
    appointment: Model
    active: boolean
}

export default function StatisticsAppointment({ appointment, active = false }: StatisticsAppointmentComponentProps) {
    const theme = useMantineTheme()

    // Convert hex color to RGB values
    function hexToRgbWithOpacity(hex: string, opacity: number): string {
        const r = parseInt(hex.substring(1, 3), 16)
        const g = parseInt(hex.substring(3, 5), 16)
        const b = parseInt(hex.substring(5, 7), 16)

        console.log('Color is', r, g, b)

        return `rgba(${r},${g},${b}, ${opacity})`
    }

    const foregroundColor = active ? theme.colors.blue[7] : undefined

    return <Group align={'center'} px={'md'}>
        <Stack style={{
            flexGrow: 1
        }} spacing={0}>
            <Text fw={700} color={foregroundColor}>
                {moment(appointment.appointmentAt).calendar()}
            </Text>
            <Divider color={foregroundColor}></Divider>
            {/* <Text color={foregroundColor}>Environ ≈ 20min</Text> */}
        </Stack>

        <Paper
            radius={16}
            style={{
                flexGrow: 4,
                backgroundColor: hexToRgbWithOpacity(theme.colors.blue[1], 0.3)
            }}
        >
            <Group
                m={'md'}
                mx={'lg'}
                spacing={'lg'}
            >
                <Title order={1} color={foregroundColor}>{appointment.number}</Title>

                <Stack spacing={4}>
                    <Title order={3}>{appointment.patient?.fullname}</Title>
                    <Title order={3} fw={400}>{appointment.motive}</Title>
                </Stack>
            </Group>
        </Paper>
    </Group>
}
