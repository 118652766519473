import { Button, Modal, Stack } from '@mantine/core'
import { useEffect } from 'react'
import User from '../../../models/user'
import { useDeleteStaffStore } from './deleteStaffStore'

interface DeleteStaffModalProps {
    close: () => void
    staff: User | null
}

function DeleteStaff({ close, staff }: DeleteStaffModalProps) {
    const store = useDeleteStaffStore()

    useEffect(() => {
        if (store.status === 'completed') {
            close()
        }
    }, [store.status])

    return (
        <Modal withCloseButton={false}
            closeOnClickOutside={false}
            closeOnEscape={false} centered
            overlayOpacity={0.55} overlayBlur={3}
            onClose={close}
            opened={!!staff}
            padding={'xl'}
            radius={'lg'}>

            <Stack
                align={'stretch'}
                spacing={'xl'}
                mx={'xl'}
                style={{ textAlign: 'center' }}>

                <h3>Are you sure you want to delete this staff
                    &nbsp;{staff?.namePrefix ?? ''} {staff?.lastName ?? ''} {staff?.firstName ?? ''}?</h3>
                <p>By deleting staff&apos;s Profile,
                    you will lose
                <br/>ALL DATA regarding this staff
                <br />(appointments, consultations and etc).</p>

                <Button
                    onClick={() => {
                        if (!staff) return

                        void store.controls.deleteStaff(staff?.id)
                    }}
                    loading={store.status === 'pending'}
                    fullWidth
                    size="lg" color="red"
                    radius="md">Confirm delete</Button>
                <Button
                    onClick={close}
                    fullWidth
                    size="lg"
                    color="yamnaGreen"
                    radius="md">Cancel</Button>
            </Stack>
        </Modal>
    )
}

export default DeleteStaff
