import {
    Button, Drawer, Grid,
    Group, LoadingOverlay, ScrollArea, Stack, Tabs, Text
} from '@mantine/core'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Edit2, Trash } from 'react-feather'
import AppointmentComponent from '../../../components/Appointment'
import Appointment from '../../../models/appointment'
import Patient from '../../../models/patient'
import { useAppointmentsStore } from '../../../stores/appointmentsStore'
import MedicinesModal from '../../Appointments/AppointmentWorkflowComponents/MedicinesModal'
import NotesModal from '../../Appointments/AppointmentWorkflowComponents/NotesModal'
import PaymentStepModal from '../../Appointments/AppointmentWorkflowComponents/PaymentStepModal'
import { useAppointmentWorkflowStore } from '../../Appointments/appointmentWorkflowStore'
import DeleteAppointmentModalComponent from '../../Appointments/DeleteAppointment'
import EditAppointmentForm from '../../Appointments/EditAppointmentForm'
import { usePatientViewStore } from './patientViewStore'

interface ShowPatientDrawerContentProps {
    patient: Patient
    editPatient: () => void
    deletePatient: () => void
}

export default function ShowPatientDrawerContent(props: ShowPatientDrawerContentProps) {
    const store = usePatientViewStore()
    useEffect(() => {
        store.controls.fetchPatient(props.patient.id)
    }, [store.controls, props.patient])

    const appointmentsStore = useAppointmentsStore()
    const workflowStore = useAppointmentWorkflowStore()
    const { patient } = store

    const [currentDeletingAppointment, setCurrentDeletingAppointment] = useState<Appointment | null>(null)
    const [currentEditingAppointment, setCurrentEditingAppointment] = useState<Appointment | null>(null)
    const [currentStartedAppointment, setCurrentStartedAppointment] = useState<Appointment | null>(null)

    function getAge(dateString: Patient['birthday']) {
        const lifeDuration = moment.duration(moment().diff(moment(dateString)))

        return `${lifeDuration.years()} years ${lifeDuration.months()} months`
    }

    return (
        <div>
            <LoadingOverlay visible={store.isFetchPending}></LoadingOverlay>
            <div>
                <Text
                    weight={700}
                    color="yamnaGreen"
                    size='xl'
                >{patient?.fullname ?? 'Loading...'}</Text>

                <Text
                    weight={600}
                    color={'dimmed'}
                    mt={20}
                >{patient?.adresse ?? 'Loading...'}</Text>
                <Text
                    weight={600}
                    color={'dimmed'}
                >{patient?.phone ?? 'Loading...'}</Text>
                <Text
                    weight={600}
                    color={'dimmed'}
                >{patient?.email ?? 'Loading...'}</Text>

                <Tabs
                    mt={25}
                    defaultValue="details"
                >
                    <Tabs.List grow>
                        <Tabs.Tab value="details">Details</Tabs.Tab>
                        <Tabs.Tab value="appointments">Appointments</Tabs.Tab>
                        <Tabs.Tab value="history">History</Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value='details' mt={'md'}>
                        <Grid grow gutter="md">
                            <Grid.Col span={12}>
                                <Group position="apart">
                                    <Text weight={600} color={'dimmed'}>Age</Text>
                                    <Text weight={700} color="yamnaGreen">{
                                        patient ? getAge(patient.birthday) : 'Loading ...'
                                    }</Text>
                                </Group>
                                <Group position="apart" mt={15}>
                                    <Text weight={600} color="gray">Sexe</Text>
                                    <Text weight={700} color="yamnaGreen">{patient?.sexe ?? 'Loading...'}</Text>
                                </Group>
                                <Group position="apart" mt={15}>
                                    <Text weight={600} color="gray">Taille</Text>
                                    <Text weight={700} color="yamnaGreen">{patient?.size ?? 'Loading...'}</Text>
                                </Group>
                                <Group position="apart" mt={15}>
                                    <Text weight={600} color="gray">Poids</Text>
                                    <Text weight={700} color="yamnaGreen">{patient?.weight ?? 'Loading...'}</Text>
                                </Group>
                                <Group position="apart" mt={15}>
                                    <Text weight={600} color="gray">IMC</Text>
                                    <Text weight={700} color="yamnaGreen">{patient?.imc ?? 'Loading...'}</Text>
                                </Group>
                                <Group position="apart" mt={15}>
                                    <Text weight={600} color="gray">Groupe sanguin</Text>
                                    <Text weight={700} color="yamnaGreen">{patient?.bloodType ?? 'Loading...'}</Text>
                                </Group>
                                <Group position="apart" mt={15}>
                                    <Text weight={600} color="gray">Diagnostic</Text>
                                    <Text weight={700} color="yamnaGreen">{patient?.diagnostic ?? 'Loading...'}</Text>
                                </Group>
                            </Grid.Col>
                            <Grid.Col span={12}>
                                <Button fullWidth leftIcon={<Edit2 />}
                                    size="lg" color="orange"
                                    className="rightIconFullWidth"
                                    onClick={props.editPatient}
                                >Edit information</Button>
                            </Grid.Col>
                            <Grid.Col span={12}>
                                <Button fullWidth leftIcon={<Trash />}
                                    size="lg" color="red"
                                    className="rightIconFullWidth"
                                    onClick={props.deletePatient}
                                >Delete patient</Button>
                            </Grid.Col>
                        </Grid>
                    </Tabs.Panel>
                    <Tabs.Panel
                        value="appointments"
                        mt={'md'}
                        style={{
                            maxHeight: '70vh'
                        }}
                    >
                        <ScrollArea style={{ height: '70vh' }}>
                            <Stack
                                spacing={'xs'}
                                align='stretch'
                                style={{
                                    display: 'flex', // use flexbox
                                    flexDirection: 'column', // set flex direction to 'column'
                                    position: 'relative',
                                    flexGrow: 1 // make the container element expand to fill the available space
                                }}
                            >
                                {
                                    patient?.appointments?.length === 0 && <Text align='center'>No Appointments</Text>
                                }
                                {
                                    patient?.appointments?.map((appointment, index) => {
                                        return (
                                            <AppointmentComponent
                                                appointment={appointment}
                                                onDelete={() => setCurrentDeletingAppointment(appointment)}
                                                onEdit={() => setCurrentEditingAppointment(appointment)}
                                                startConsultation={() => {
                                                    setCurrentStartedAppointment(appointment)
                                                    workflowStore.controls.startWorkflow()
                                                }}
                                                key={index}
                                            ></AppointmentComponent>
                                        )
                                    }) ?? 'Loading ...'
                                }
                            </Stack>
                        </ScrollArea>
                    </Tabs.Panel>
                    <Tabs.Panel value="history">
                        <Stack align={'stretch'} mt={'md'}>
                            <Text align='center' color={'dimmed'}>Coming soon!</Text>
                        </Stack>
                    </Tabs.Panel>
                </Tabs>
            </div>

            <Drawer
                position='right'
                opened={currentEditingAppointment !== null}
                onClose={() => setCurrentEditingAppointment(null)}
                padding='xl'
                styles={{
                    closeButton: {
                        display: 'none'
                    }
                }}>
                { currentEditingAppointment &&
                    <EditAppointmentForm
                        closeModal={() => setCurrentEditingAppointment(null)}
                        appointment={currentEditingAppointment}
                    ></EditAppointmentForm>
                }
            </Drawer>

            <DeleteAppointmentModalComponent
                state={currentDeletingAppointment !== null}
                deletePending={appointmentsStore.isDeletePending}
                confirmDelete={() => {
                    if (currentDeletingAppointment != null) {
                        void appointmentsStore.controls.deleteAppointment(currentDeletingAppointment.id)
                            .then(() => setCurrentDeletingAppointment(null))
                            .then(() => store.controls.fetchPatient(props.patient.id))
                    }
                }}
                cancelDelete={() => setCurrentDeletingAppointment(null)} />

            { currentStartedAppointment && <>
                <NotesModal
                    appointmentId={currentStartedAppointment.id}
                    cancel={workflowStore.controls.cancel}
                    modalState={workflowStore.currentWorkflowStep === 'notes'}
                    continueWithResult={(note) => {
                        void workflowStore.controls.setNote(currentStartedAppointment.id, note.notes)
                            .then(() => store.controls.fetchPatient(props.patient.id))
                    }}
                ></NotesModal>

                <MedicinesModal
                    appointment={currentStartedAppointment}
                    cancel={workflowStore.controls.cancel}
                    modalState={workflowStore.currentWorkflowStep === 'medicines'}
                    continueWithResult={(value) => {
                        void workflowStore.controls.setMedicines(currentStartedAppointment.id, value.medicines)
                            .then(() => store.controls.fetchPatient(props.patient.id))
                    }}
                ></MedicinesModal>

                <PaymentStepModal
                    appointmentId={currentStartedAppointment.id}
                    cancel={workflowStore.controls.cancel}
                    modalState={workflowStore.currentWorkflowStep === 'payment'}
                    continueWithResult={(value) => {
                        void workflowStore.controls
                            .setPaymentInfo(currentStartedAppointment.id, value.toPay, value.paid, value.notifyAssistant)
                            .then(() => store.controls.fetchPatient(props.patient.id))
                    }}
                ></PaymentStepModal>
            </>
            }
        </div>
    )
}
