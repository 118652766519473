import { ActionIcon, Button, Card, Drawer, Group, LoadingOverlay, Stack, Table, Text } from '@mantine/core'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Edit2, Eye, PlusSquare, Trash } from 'react-feather'
import Patient from '../../models/patient'
import { usePatientsStore } from '../../stores/patientsStore'
import AddPatientForm from './AddPatient'
import DeleteAppointmentModalComponent from './DeletePatients'
import EditPatientForm from './edit/EditPatient'
import ShowPatientDrawerContent from './view/ShowPatient'

export default function Patients() {
    const patientsStore = usePatientsStore()

    useEffect(() => {
        patientsStore.controls.searchPatient({ query: '' })
    }, [patientsStore.controls])

    const [isAddDrawerOpen, setDrawerAddOpened] = useState(false)
    const [currentEditingPatient, setCurrentEditingPatient] = useState<Patient | null>(null)
    const [currentDeletingPatient, setCurrentDeletingPatient] = useState<Patient | null>(null)
    const [currentViewingPatient, setCurrentViewingPatient] = useState<Patient | null>(null)

    function getAge(dateString: string) {
        const lifeDuration = moment.duration(moment().diff(moment(dateString)))

        return `${lifeDuration.years()} years ${lifeDuration.months()} months`
    }

    return <div>
        <LoadingOverlay visible={patientsStore.isFindPending} ></LoadingOverlay>
        <Card
            radius={'lg'}
            shadow={'md'}
            mx={'md'}
            px={'xl'}
        >
            <Stack>
                <Group position="apart">
                    <h2>List des patients</h2>
                    <Button leftIcon={<PlusSquare />}
                        size="lg"
                        onClick={() => setDrawerAddOpened(true)}
                        pr={128}
                        radius={'md'}
                    >Add new patient
                    </Button>
                </Group>
                { isAddDrawerOpen }
                <Table striped highlightOnHover
                    horizontalSpacing="xl" verticalSpacing="md"
                    className="cusTomTable">
                    <thead>
                        <tr>
                            <th>Nom et prénom</th>
                            <th>Age</th>
                            <th>Sexe</th>
                            <th>IMC</th>
                            <th>Groupe sanguin</th>
                            <th>Maladie</th>
                            <th>Ville</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            patientsStore.patients.map((patient) => {
                                return (
                                    <tr key={patient.id} >
                                        <td><Text weight={600}>{patient.fullname}</Text></td>
                                        <td>{getAge(patient.birthday)}</td>
                                        <td>{patient.sexe}</td>
                                        <td>{patient.imc}</td>
                                        <td>{patient.bloodType}</td>
                                        <td>{patient.diagnostic}</td>
                                        <td>{patient.adresse}</td>
                                        <td>
                                            <Group>
                                                <ActionIcon color="yamnaGreen" radius="xl"
                                                    variant="filled"
                                                    onClick={() => {
                                                        setCurrentViewingPatient(patient)
                                                    }}
                                                >
                                                    <Eye size={15} />
                                                </ActionIcon>
                                                <ActionIcon color="yamnaGreen" radius="xl"
                                                    variant="filled" onClick={() => {
                                                        setCurrentEditingPatient(patient)
                                                    }}>
                                                    <Edit2 size={15} />
                                                </ActionIcon>
                                                <ActionIcon color="yamnaGreen" radius="xl"
                                                    variant="filled" onClick={() => {
                                                        setCurrentDeletingPatient(patient)
                                                    }}>
                                                    <Trash size={15} />
                                                </ActionIcon>
                                            </Group>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </Stack>
        </Card>

        <Drawer
            position='right'
            opened={isAddDrawerOpen}
            onClose={() => setDrawerAddOpened(false)}
            padding='xl'
            size={'xl'}
            styles={{
                closeButton: {
                    display: 'none'
                }
            }}>
            <AddPatientForm close={ () => setDrawerAddOpened(false) }></AddPatientForm>
        </Drawer>

        <Drawer
            position='right'
            opened={currentEditingPatient != null}
            onClose={() => setCurrentEditingPatient(null)}
            padding='xl'
            size={'xl'}
            styles={{
                closeButton: {
                    display: 'none'
                }
            }}>
            { currentEditingPatient
                ? <EditPatientForm
                    close={ () => setCurrentEditingPatient(null) }
                    patient={currentEditingPatient}
                    onComplete={() => patientsStore.controls.searchPatient({ query: '' })}
                ></EditPatientForm>
                : <></>
            }
        </Drawer>

        <Drawer
            position='right'
            opened={currentViewingPatient != null}
            onClose={() => setCurrentViewingPatient(null)}
            padding='xl'
            size={'xl'}
            styles={{
                closeButton: {
                    display: 'none'
                }
            }}>
            { currentViewingPatient
                ? <ShowPatientDrawerContent
                    patient={currentViewingPatient}
                    editPatient={() => {
                        setCurrentEditingPatient(currentViewingPatient)
                        setCurrentViewingPatient(null)
                    }}
                    deletePatient={() => {
                        setCurrentDeletingPatient(currentViewingPatient)
                        setCurrentViewingPatient(null)
                    }}
                ></ShowPatientDrawerContent>
                : <></>
            }
        </Drawer>

        <DeleteAppointmentModalComponent
            cancelDelete={() => setCurrentDeletingPatient(null) }
            state={currentDeletingPatient !== null}
            deletePending={patientsStore.deletePending}
            confirmDelete={() => {
                if (currentDeletingPatient === null) return

                void patientsStore.controls.deletePatient(currentDeletingPatient?.id)
                    .then(() => setCurrentDeletingPatient(null))
            }}
        />
    </div>
}
