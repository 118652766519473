import create from 'zustand'
import User from '../../models/user'
import client from '../../api/ApiClient'
import { useAuthStore } from '../../stores/authStore'

interface UserProfileEditParams {
    namePrefix: string
    firstName: string
    lastName: string
    phoneNumber: string
    email: string
    speciality: string
    description: string
}

interface ProfileStore {
  user: User | null
  isFetchPending: boolean
  saveInProgress: boolean
  errorMessage: string | null
  controls: {
    loadCurrentUser: (force?: boolean) => Promise<void>
    updateProfile: (data: UserProfileEditParams) => Promise<void>
  }
}

const useProfileStore = create<ProfileStore>((set, get) => ({
    user: null,
    isFetchPending: false,
    errorMessage: null,
    saveInProgress: false,
    loggedIn: false,
    controls: {
        loadCurrentUser: async (force: boolean = false) => {
            set({
                isFetchPending: true,
                errorMessage: null
            })
            await client
                .get('/auth/currentUser')
                .then((response) => {
                    const user = response.data

                    set({
                        user,
                        isFetchPending: false
                    })
                })
                .catch((error) => {
                    set(() => ({
                        errorMessage: error.errorMessage
                    }))
                })
                .finally(() => set(() => ({ isFetchPending: false })))
        },
        updateProfile: async (data) => {
            set({
                saveInProgress: true
            })

            const { user } = useAuthStore.getState()

            if (!user) throw new Error('User is not authenticated!')

            await client
                .patch('/user/current', data)
                .then((response) => {
                    const user = response.data

                    set({
                        user,
                        saveInProgress: false
                    })
                    useAuthStore.setState({ user })
                })
                .catch((error) => {
                    set(() => ({
                        errorMessage: error.errorMessage
                    }))
                })
                .finally(() => set(() => ({ saveInProgress: false })))
        }
    }
}))

export { useProfileStore }
