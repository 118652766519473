import { AxiosResponse } from 'axios'
import create from 'zustand'
import axiosClient from '../../../api/ApiClient'
import Role from '../../../models/role'
import User, { Schedule } from '../../../models/user'

export interface UpdateStaffReqDto {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    roleId: number
    speciality: string
    permissions: string[]
    schedule: Schedule
}

interface EditStaffStore {
  roles: Role[]
  saveInProgress: boolean
  rolesFetching: boolean
  controls: {
    updateStaff: (
        id: number,
        data: UpdateStaffReqDto
    ) => Promise<void>
    fetchRoles: () => Promise<void>
  }
}

const useEditStaffStore = create<EditStaffStore>((set) => ({
    saveInProgress: false,
    rolesFetching: false,
    roles: [],
    controls: {
        updateStaff: async (
            id,
            data
        ) => {
            set({ saveInProgress: true })

            await axiosClient.patch(`/user/${id}`, data).then((response: AxiosResponse<User>) => response.data)
                .finally(() => set({ saveInProgress: false }))
        },
        fetchRoles: async () => {
            set({ rolesFetching: false })

            await axiosClient.get('/role').then((response: AxiosResponse<Role[]>) => response.data)
                .then((roles) => set({ roles }))
                .finally(() => set({ rolesFetching: false }))
        }
    }
}))

export { useEditStaffStore }
