import {
    Card,
    Grid,
    Group,
    LoadingOverlay,
    Popover,
    ScrollArea,
    Stack,
    Text,
    Title, useMantineTheme
} from '@mantine/core'
import { RangeCalendar } from '@mantine/dates'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { Calendar, ChevronDown, Clock, FileText, List, Monitor, User, Users } from 'react-feather'
import { FaMoneyBill } from 'react-icons/fa'
import StatisticsAppointment from './components/Appointment'
import { useStatisticsStore } from './statisticsStore'

interface StatCardData { title: string, value: string, subtitle: string, icon: React.ReactNode }

export default function Statistiques() {
    const theme = useMantineTheme()

    const store = useStatisticsStore()

    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
        new Date(),
        new Date()
    ])

    useEffect(() => {
        if (!dateRange[1]) return

        void store.controls.fetchStatistics({
            start: moment(dateRange[0]).startOf('day').toISOString(),
            end: moment(dateRange[1]).endOf('day').toISOString()
        })
    }, [store.controls, dateRange])

    const statisticsData: StatCardData[] | null = useMemo(() => {
        if (!store.statsData) return null

        const data: StatCardData[] = []

        data.push({
            title: 'Appointments',
            value: store.statsData.appointments.length.toString(),
            subtitle: 'Total',
            icon: <Calendar color={theme.colors.blue[4]}></Calendar>
        })

        data.push({
            title: '≈ Consultation',
            value: `${moment.duration(store.statsData.averageConsultationDuration).minutes()} min`,
            subtitle: 'Durée moyenne',
            icon: <Clock color={theme.colors.blue[4]}></Clock>
        })

        data.push({
            title: 'Patients',
            value: store.statsData.patients.toString(),
            subtitle: 'New',
            icon: <User color={theme.colors.blue[4]}></User>
        })

        data.push({
            title: 'Staff',
            value: store.statsData.staffs.toString(),
            subtitle: 'Actives',
            icon: <Users color={theme.colors.blue[4]}></Users>
        })

        data.push({
            title: 'Web visits',
            value: '1460',
            subtitle: 'Coming soon',
            icon: <Monitor color={theme.colors.blue[4]}></Monitor>
        })

        data.push({
            title: 'Revenue',
            value: '35 000 DA',
            subtitle: 'Coming soon',
            icon: <FaMoneyBill size={24} color={theme.colors.blue[4]}></FaMoneyBill>
        })

        return data
    }, [store.statsData])

    return <Stack align={'start'} spacing={0}>
        <Popover
            position="bottom"
            withArrow
            shadow="md"
        >
            <Popover.Target>
                <Group
                    px={'md'}
                    mx={'md'}
                    style={{
                        flexGrow: 0
                    }}>
                    <Title order={3}>
                        {moment(dateRange[0]).format('ll')} - {moment(dateRange[1]).format('ll')}
                    </Title>

                    <ChevronDown></ChevronDown>
                </Group>
            </Popover.Target>
            <Popover.Dropdown>
                <RangeCalendar value={dateRange} onChange={setDateRange}></RangeCalendar>
            </Popover.Dropdown>
        </Popover>
        <Grid
            columns={9}
            p={'lg'}
            gutter={'sm'}
            style={{
                width: '100%'
            }}
        >
            <Grid.Col span={3}>
                <ScrollArea
                    h={'58vh'}
                >
                    <Grid
                        columns={2}
                        style={{
                            width: '95%'
                        }}
                    >
                        { statisticsData
                            ? statisticsData.map((statistic) => <Grid.Col span={1} key={statistic.title}>
                                <Card
                                    shadow={'xs'}
                                    radius={'lg'}
                                    p={'lg'}
                                >
                                    <Stack spacing={'sm'}>
                                        {statistic.icon}
                                        <Title size={'xl'}>{statistic.title}</Title>
                                        <Text size={28} p={0}>{statistic.value}</Text>
                                        <Text span>{statistic.subtitle} &nbsp;</Text>
                                    </Stack>
                                </Card>
                            </Grid.Col>
                            )
                            : <LoadingOverlay visible={statisticsData === null}></LoadingOverlay>
                        }
                    </Grid>
                </ScrollArea>
            </Grid.Col>

            <Grid.Col span={4}>
                <Card
                    shadow={'xs'}
                    radius={'lg'}
                    p={'xl'}
                    h={'58vh'}
                >
                    <Stack spacing={'sm'} style={{
                        height: '100%'
                    }}>
                        <Stack px={'md'} spacing={'sm'}>
                            <FileText color={theme.colors.blue[4]}></FileText>
                            <Title size={'xl'}>Appointments list</Title>
                        </Stack>
                        <ScrollArea h={'100%'}>
                            <Stack>
                                {
                                    store.statsData?.appointments.map((appointment, index) =>
                                        <StatisticsAppointment
                                            appointment={appointment}
                                            active={index === 0}
                                            key={appointment.id}
                                        ></StatisticsAppointment>
                                    )
                                }
                            </Stack>
                        </ScrollArea>
                    </Stack>
                </Card>
            </Grid.Col>

            <Grid.Col span={2}>
                <Card
                    shadow={'xs'}
                    radius={'lg'}
                    h={'58vh'}
                    p={'xl'}
                >
                    <Stack spacing={'sm'}>
                        <Stack px={'md'} spacing={'sm'}>
                            <List color={theme.colors.blue[4]}></List>
                            <Title size={'xl'}>Last activity</Title>
                        </Stack>

                        <ScrollArea h={'100%'}>
                            <Stack px={'md'}>
                                <Text>Coming soon</Text>
                            </Stack>
                        </ScrollArea>
                    </Stack>
                </Card>
            </Grid.Col>
        </Grid>
    </Stack>
}
