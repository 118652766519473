import moment from 'moment'
import create from 'zustand'
import axiosClient from '../api/ApiClient'
import Appointment from '../models/appointment'
import { NewAppointmentFormData, NewPatientFormData } from '../pages/Appointments/AddAppointmentForm'

interface AppointmentFetchParams {
    from?: string
    to?: string
}

interface AppointmentsStore {
  isFetchPending: boolean
  isCreatePending: boolean
  isDeletePending: boolean
  isPatchPending: boolean
  areFreeDaysUpdatePending: boolean
  appointments: Appointment[]
  busyDays: string[]
  controls: {
    lastFetchFilter: AppointmentFetchParams
    loadAppointments: (filter: AppointmentFetchParams) => Promise<void>
    updateFreeDaysStat: (dateOfMonth: string) => Promise<void>
    createNewAppointment: (appointmentData: NewAppointmentFormData, patientData?: NewPatientFormData) => Promise<void>
    updateAppointment: (id: number, appointmentData: NewAppointmentFormData, patientData?: NewPatientFormData) => Promise<void>
    deleteAppointment: (appointmentId: number) => Promise<void>
  }
}

const useAppointmentsStore = create<AppointmentsStore>((set, get) => ({
    appointments: [],
    isFetchPending: false,
    isCreatePending: false,
    isDeletePending: false,
    isPatchPending: false,
    areFreeDaysUpdatePending: false,
    busyDays: [],
    controls: {
        lastFetchFilter: {},
        loadAppointments: async (filter: AppointmentFetchParams) => {
            set(() => ({
                isFetchPending: true
            }))

            get().controls.lastFetchFilter = filter

            const params = new URLSearchParams()
            if (filter.from) params.set('from', filter.from)
            if (filter.to) params.set('to', filter.to)

            return await axiosClient.get('/appointment', { params }).then(response => response.data)
                .then(appointmentsList => {
                    set(() => ({
                        appointments: appointmentsList
                    }))
                }).finally(() => {
                    set(() => ({
                        isFetchPending: false
                    }))
                })
        },

        updateFreeDaysStat: async (dateOfMonth) => {
            set(() => ({
                areFreeDaysUpdatePending: true
            }))

            const params = new URLSearchParams()
            params.set('monthDate', dateOfMonth)

            return await axiosClient.get('/appointment/busy-days', { params }).then(response => response.data)
                .then(busyDaysList => {
                    set(() => ({
                        busyDays: busyDaysList.map((date: string) => moment(date).format('L'))
                    }))
                }).finally(() => {
                    set(() => ({
                        areFreeDaysUpdatePending: false
                    }))
                })
        },

        createNewAppointment: async (appointmentData: NewAppointmentFormData, patientData?: NewPatientFormData) => {
            set(() => ({
                isCreatePending: true
            }))

            await axiosClient.post('/appointment', {
                doctorId: appointmentData.doctorId,
                appointmentAt: appointmentData.appointmentDate,
                patientId: appointmentData.patientId,
                patient: {
                    fullname: patientData?.fullName,
                    adresse: patientData?.address,
                    sexe: patientData?.gender,
                    email: patientData?.email,
                    birthday: patientData?.birthday,
                    phone: patientData?.phone
                }
            }).then(() => {
                void get().controls.loadAppointments(get().controls.lastFetchFilter)
                void get().controls.updateFreeDaysStat(get().controls.lastFetchFilter.from ?? moment().format())
            }).finally(() => {
                set(() => ({
                    isCreatePending: false
                }))
            })
        },

        deleteAppointment: async (id) => {
            set(() => ({
                isCreatePending: true
            }))

            await axiosClient.delete('/appointment/' + id.toString()).then(() => {
                void get().controls.loadAppointments(get().controls.lastFetchFilter)
                void get().controls.updateFreeDaysStat(get().controls.lastFetchFilter.from ?? moment().format())
            }).finally(() => {
                set(() => ({
                    isCreatePending: false
                }))
            })
        },

        updateAppointment: async (id, appointmentData, patientData) => {
            set(() => ({
                isPatchPending: true
            }))

            await axiosClient.patch('/appointment/' + id.toString(), {
                doctorId: appointmentData.doctorId,
                appointmentAt: appointmentData.appointmentDate,
                patientId: appointmentData.patientId,
                patient: {
                    fullname: patientData?.fullName,
                    adresse: patientData?.address,
                    sexe: patientData?.gender,
                    email: patientData?.email,
                    birthday: patientData?.birthday,
                    phone: patientData?.phone
                }
            }).then(() => {
                void get().controls.loadAppointments(get().controls.lastFetchFilter)
                void get().controls.updateFreeDaysStat(get().controls.lastFetchFilter.from ?? moment().format())
            }).finally(() => {
                set(() => ({
                    isPatchPending: false
                }))
            })
        }
    }
}))

export { useAppointmentsStore }
