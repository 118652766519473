import Appointment from './appointment'
import BaseType from './base'
import Clinic from './clinic'
import Role from './role'

export const WeekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] as const

export type Schedule = Partial<Record<typeof WeekDays[number], { start: string, end: string }>>

type User = {
  email: string
  firstName?: string
  lastName?: string
  phoneNumber?: string
  clinic?: Clinic
  clinicId?: number

  isVerified: boolean

  namePrefix?: string
  speciality?: string
  description?: string

  tariff: 'BASIC' | 'PRO'
  owen?: Clinic
  appointments: Appointment[]

  roleId?: number
  role?: Role

  permissions: string[]
  schedule: Schedule
} & BaseType

export default User
