import Appointment from './appointment'
import BaseType from './base'
import Clinic from './clinic'

export const BloodTypes = ['O+', 'O-', 'A+', 'A-', 'B+', 'B-', 'AB+', 'AB-'] as const
export const Sexe = ['Male', 'Female'] as const

interface Patient extends BaseType {
    fullname: string
    adresse: string
    phone: string
    email: string
    birthday: string
    sexe: typeof Sexe[number]

    size?: number
    weight?: number
    imc?: string
    bloodType?: typeof BloodTypes[number]
    diagnostic?: string

    clinic?: Clinic
    clinicId: number
    appointments?: Appointment[]
}

export default Patient
