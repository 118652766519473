import { Group } from '@mantine/core'
import { Star } from 'react-feather'

interface ReviewRateStarsProps {
    rate: number
}

export default function ReviewRateStars(props: ReviewRateStarsProps) {
    const stars = []
    for (let i = 0; i < props.rate; i++) {
        stars.push(
            <Star
                key={i}
                fill={'#FFB200'}
                color={'#FFB200'}
                size={16}
            ></Star>)
    }
    return <Group spacing={1}>
        {stars}
    </Group>
}
