import { ActionIcon, Box, Button, Drawer, Grid, Group, Loader, Stack, Table, useMantineTheme } from '@mantine/core'
import { Calendar } from '@mantine/dates'
import moment from 'moment'
import { CSSProperties, useEffect, useMemo, useState } from 'react'
import { Calendar as CalendarIcon, Edit2, Eye, Trash } from 'react-feather'
import Appointment from '../../models/appointment'
import { useAppointmentsStore } from '../../stores/appointmentsStore'
import NewAppointmentForm from './AddAppointmentForm'
import DeleteAppointmentModalComponent from './DeleteAppointment'
import EditAppointmentForm from './EditAppointmentForm'
import ShowAppointmentComponents from './ShowAppointment'

function Appointments() {
    const [drawerAddOpened, setDrawerAddOpened] = useState(false)
    const [drawerViewOpened, setDrawerViewOpened] = useState(false)
    const [deleteConfirmRequest, setDeleteConfirmRequest] = useState(false)
    const [drawerEditOpened, setDrawerEditOpened] = useState(false)
    const [selectedAppointment, setSelectAppointment] = useState<Appointment | null>(null)

    const [calanderDate, setCalanderDate] = useState(new Date(Date.now()))
    const theme = useMantineTheme()
    const appointmentsStore = useAppointmentsStore()
    const { busyDays, appointments, controls } = appointmentsStore

    const month = useMemo(() => moment(calanderDate).startOf('month').format(), [calanderDate])

    useEffect(() => {
        void controls.loadAppointments({
            from: moment(calanderDate).startOf('day').format(),
            to: moment(calanderDate).endOf('day').format()
        })
    }, [controls, calanderDate])

    useEffect(() => {
        void controls.updateFreeDaysStat(month)
    }, [month, controls])

    return (
        <div>
            <Grid className='appointments' grow
                mt={30} >
                <Grid.Col span={3}>
                    <div className="box">
                        <Calendar
                            fullWidth
                            initialMonth={new Date()}
                            onMonthChange={(date) => setCalanderDate(date)}
                            dayStyle={(date) => {
                                const defaultStyle: CSSProperties = {
                                    border: '1px solid black',
                                    borderRadius: '50%',
                                    color: '#033446',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    margin: '2px',
                                    boxSizing: 'border-box',
                                    height: '40px',
                                    width: '40px'
                                }
                                const today = moment(date).startOf('day').format() ===
                                    moment(calanderDate).startOf('day').format()

                                if (today) {
                                    defaultStyle.backgroundColor = theme.colors.yamnaPink[4]
                                    defaultStyle.color = theme.white
                                }

                                const disabled = !busyDays.includes(moment(date).format('L'))

                                if (disabled) {
                                    defaultStyle.opacity = 0.35
                                }

                                return defaultStyle
                            }}
                            onChange={(date) => { if (date) setCalanderDate(date) }}
                        />
                    </div>
                </Grid.Col>
                <Grid.Col span={9} >
                    <div className="box">
                        <Stack>
                            <Group position="apart">
                                <h2>{moment(calanderDate).format('dddd, LL')}</h2>
                                <Button leftIcon={<CalendarIcon />}
                                    size="md"
                                    onClick={() => setDrawerAddOpened(true)}
                                >New appointment</Button>
                            </Group>
                            <Box>
                                {
                                    appointmentsStore.isFetchPending
                                        ? <Stack align={'center'}>
                                            <Loader></Loader>
                                        </Stack>
                                        : <Table
                                            striped
                                            highlightOnHover
                                            horizontalSpacing="xl"
                                            verticalSpacing="md"
                                            className="cusTomTable">
                                            <thead>
                                                <tr>
                                                    <th>Full name</th>
                                                    <th>Number</th>
                                                    <th>Time</th>
                                                    <th>Doctor</th>
                                                    <th>Motive</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    appointments.map((appointment, index) => {
                                                        return (
                                                            <tr key={appointment.id} >
                                                                <td>{appointment.patient?.fullname}</td>
                                                                <td>{appointment.number}</td>
                                                                <td>{moment(appointment.appointmentAt).format('LLL')}</td>
                                                                <td>{appointment.doctor?.firstName}
                                                                   &nbsp; {appointment.doctor?.lastName}</td>
                                                                <td>{appointment.motive}</td>
                                                                <td>
                                                                    <Group>
                                                                        <ActionIcon color="yamnaGreen"
                                                                            radius="xl"
                                                                            variant="filled"
                                                                            onClick={() => {
                                                                                setSelectAppointment(appointment)
                                                                                setDrawerViewOpened(true)
                                                                            }}
                                                                        >
                                                                            <Eye size={15} />
                                                                        </ActionIcon>
                                                                        <ActionIcon
                                                                            color="yamnaGreen"
                                                                            radius="xl"
                                                                            variant="filled"
                                                                            onClick={() => {
                                                                                setSelectAppointment(appointment)
                                                                                setDrawerEditOpened(true)
                                                                            }}
                                                                        >
                                                                            <Edit2 size={15} />
                                                                        </ActionIcon>
                                                                        <ActionIcon
                                                                            color="yamnaGreen"
                                                                            radius="xl"
                                                                            variant="filled"
                                                                            onClick={() => {
                                                                                setSelectAppointment(appointment)
                                                                                setDeleteConfirmRequest(true)
                                                                            }}
                                                                        >
                                                                            <Trash size={15} />
                                                                        </ActionIcon>
                                                                    </Group>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                }
                            </Box>
                        </Stack>
                    </div>

                </Grid.Col>
            </Grid >

            <Drawer
                position='right'
                opened={drawerAddOpened}
                onClose={() => setDrawerAddOpened(false)}
                padding='xl'
                styles={{
                    closeButton: {
                        display: 'none'
                    }
                }}>
                <NewAppointmentForm
                    closeModal={() => setDrawerAddOpened(false)}
                    defaultTime={moment(calanderDate).format()}></NewAppointmentForm>
            </Drawer>

            <Drawer
                position='right'
                opened={drawerViewOpened}
                onClose={() => setDrawerViewOpened(false)}
                padding='xl'
                styles={{
                    closeButton: {
                        display: 'none'
                    },
                    drawer: {
                        minWidth: '25%'
                    }
                }}>
                { selectedAppointment
                    ? <ShowAppointmentComponents
                        appointment={selectedAppointment}
                        close={() => setDrawerViewOpened(false)}
                        onDelete={() => setDeleteConfirmRequest(true)}
                        onEdit={() => setDrawerEditOpened(true)}
                        startConsultation={() => {}}
                    ></ShowAppointmentComponents>
                    : null}

            </Drawer>

            <Drawer
                position='right'
                opened={drawerEditOpened}
                onClose={() => setDrawerEditOpened(false)}
                padding='xl'
                styles={{
                    closeButton: {
                        display: 'none'
                    }
                }}>
                { selectedAppointment &&
                    <EditAppointmentForm
                        closeModal={() => setDrawerEditOpened(false)}
                        appointment={selectedAppointment}
                    ></EditAppointmentForm>
                }
            </Drawer>

            <DeleteAppointmentModalComponent
                state={deleteConfirmRequest}
                deletePending={appointmentsStore.isDeletePending}
                confirmDelete={() => {
                    if (selectedAppointment != null) {
                        void appointmentsStore.controls.deleteAppointment(selectedAppointment.id)
                            .then(() => setDeleteConfirmRequest(false))
                    }
                }}
                cancelDelete={() => setDeleteConfirmRequest(false)} />

        </div >
    )
}

export default Appointments
