import { LoadingOverlay, ScrollArea, Stack, Text } from '@mantine/core'
import { useEffect } from 'react'
import AppointmentComponent from '../../components/Appointment'
import Appointment from '../../models/appointment'
import MedicinesModal from './AppointmentWorkflowComponents/MedicinesModal'
import NotesModal from './AppointmentWorkflowComponents/NotesModal'
import PaymentStepModal from './AppointmentWorkflowComponents/PaymentStepModal'
import { useAppointmentWorkflowStore } from './appointmentWorkflowStore'

interface ShowAppointmentDrawerProps {
    appointment: Appointment
    close: () => void
    onEdit: (appointment: Appointment) => void
    onDelete: (appointment: Appointment) => void
    startConsultation: () => void
}

function ShowAppointmentComponents(props: ShowAppointmentDrawerProps) {
    const store = useAppointmentWorkflowStore()

    useEffect(() => {
        void store.controls.fetchPatientsAppointmentsForDay({
            patientId: props.appointment.patientId,
            day: props.appointment.appointmentAt
        })
    }, [store.controls])

    return (
        <>
            <Stack style={{ height: '100%' }}>
                <Text mb={15} weight={700}>{props.appointment.patient?.fullname}</Text>
                <Text mb={0} weight={400}>{props.appointment.patient?.adresse}</Text>
                <Text mb={0} weight={400}>{props.appointment.patient?.phone}</Text>
                <Text mb={0} weight={400}>{props.appointment.patient?.email}</Text>
                <ScrollArea type='hover' style={{ flexGrow: 1, flexShrink: 1 }}>
                    <Stack>
                        <LoadingOverlay visible={store.isFetchPending}></LoadingOverlay>
                        <h2>Today&apos;s appointments</h2>
                        {
                            store.appointments.map((appointment, index) =>
                                <AppointmentComponent
                                    key={index}
                                    appointment={appointment}
                                    onDelete={() => props.onDelete(appointment)}
                                    onEdit={() => props.onEdit(appointment)}
                                    startConsultation={store.controls.startWorkflow}
                                ></AppointmentComponent>
                            )
                        }
                    </Stack>
                </ScrollArea>
            </Stack>

            <NotesModal
                appointmentId={props.appointment.id}
                cancel={store.controls.cancel}
                modalState={store.currentWorkflowStep === 'notes'}
                continueWithResult={(note) => {
                    void store.controls.setNote(props.appointment.id, note.notes)
                }}
            ></NotesModal>

            <MedicinesModal
                appointment={props.appointment}
                cancel={store.controls.cancel}
                modalState={store.currentWorkflowStep === 'medicines'}
                continueWithResult={(value) => {
                    void store.controls.setMedicines(props.appointment.id, value.medicines)
                }}
            ></MedicinesModal>

            <PaymentStepModal
                appointmentId={props.appointment.id}
                cancel={store.controls.cancel}
                modalState={store.currentWorkflowStep === 'payment'}
                continueWithResult={(value) => {
                    void store.controls.setPaymentInfo(props.appointment.id, value.toPay, value.paid, value.notifyAssistant)
                }}
            ></PaymentStepModal>
            {/* <Modal withCloseButton={false} closeOnClickOutside={false}
                closeOnEscape={false} centered
                overlayOpacity={0.55} overlayBlur={3}
                opened={openedHistoryModal} >
                {
                    <Grid grow gutter="xs">
                        <Grid.Col span={12}>
                            <h3>Ajouter les informations de rendez-vous</h3>
                        </Grid.Col>
                        <form onSubmit={formHistory.onSubmit(
                            async (values) => await createHistoryForAppointment(values))} className={classes.root
                            }>
                            <Grid.Col span={12}>
                                <Textarea size="lg" radius="lg"
                                    variant="filled" placeholder="Note"
                                    {...formHistory.getInputProps('note')} classNames={{ input: 'customInput' }} />
                            </Grid.Col>
                            <Grid.Col span={12}>
                                <TextInput size="lg" radius="lg"
                                    variant="filled" label="À Payer"
                                    placeholder="À Payer" {...formHistory.getInputProps('toPay')}
                                    classNames={{ input: 'customInput' }} />
                            </Grid.Col>
                            <Grid.Col span={12}>
                                <TextInput size="lg" radius="lg"
                                    variant="filled" label="Reste à payer"
                                    placeholder="Reste à payer" {...formHistory.getInputProps('leftToPay')}
                                    classNames={{ input: 'customInput' }} />
                            </Grid.Col>
                            <Grid.Col span={12}>
                                <Group>
                                    <Button type="submit" fullWidth
                                        size="lg" color="yamnaBlue"
                                        radius="md">Confirmer</Button>
                                </Group>
                            </Grid.Col>
                        </form>
                    </Grid>
                }
            </Modal>

            <Modal withCloseButton={false} closeOnClickOutside={false}
                closeOnEscape={false} centered
                overlayOpacity={0.55} overlayBlur={3}
                opened={openedPrescriptionModal} size="xl">
                {
                    <div>
                        <Grid grow gutter="xs">
                            <Grid.Col span={6}>
                                <Text weight={700} size="xl"
                                    color="yamnaGreen">Dr {data.doctor.firstName} {data.doctor.lastName}</Text>
                                <Text weight={400} size="lg"
                                    color="gray">{data.doctor.speciality.name}</Text>
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <Text weight={400} size="lg"
                                    color="gray">{data.doctor.phoneNumber}</Text>
                                <Text weight={400} size="lg"
                                    color="gray">{data.doctor.email}</Text>
                            </Grid.Col>
                            <Grid.Col span={12}>
                                <Divider my="sm" />
                            </Grid.Col>
                        </Grid>

                        <form onSubmit={formPrescription.onSubmit(async (values) => await addPrescription(values))} >
                            <Grid grow gutter="xs">
                                <Grid.Col span={6}>
                                    <TextInput size="lg" disabled
                                        radius="lg" variant="filled"
                                        label="Nom et prénom" placeholder="Nom et prénom"
                                        classNames={{ input: 'customInput' }} defaultValue={data.patient.fullname} />
                                </Grid.Col>
                                <Grid.Col span={2}>
                                    <TextInput size="lg" disabled
                                        radius="lg" variant="filled"
                                        label="Age" placeholder="Age"
                                        classNames={{ input: 'customInput' }} defaultValue={getAge(data.patient.birthday)} />
                                </Grid.Col>
                                <Grid.Col span={4}>
                                    <TextInput size="lg" disabled
                                        radius="lg" variant="filled"
                                        label="Date" placeholder="Date"
                                        classNames={{ input: 'customInput' }} defaultValue={moment().format('DD/MM/YYYY')} />
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <Divider my="sm" />
                                </Grid.Col>

                                {
                                    formPrescription.values.medicines.map((m, index) => (
                                        <Grid gutter="xs" p={10}
                                            span={12} key={index}>
                                            <Grid.Col span={8}>
                                                <TextInput size="lg" radius="lg"
                                                    variant="filled"
                                                    {...formPrescription.getListInputProps('medicines', index, 'name')}
                                                    placeholder={'Medicament ' + (index + 1)}
                                                    classNames={{ input: 'customInput' }} />
                                            </Grid.Col>
                                            <Grid.Col span={4}>
                                                <TextInput size="lg" radius="lg"
                                                    variant="filled"
                                                    {...formPrescription.getListInputProps('medicines', index, 'duration')}
                                                    placeholder="Durée" classNames={{ input: 'customInput' }} />
                                            </Grid.Col>
                                            <Grid.Col span={8}>
                                                <TextInput size="lg" radius="lg"
                                                    variant="filled"
                                                    {...formPrescription.getListInputProps('medicines', index, 'detail')}
                                                    placeholder={'Detail de medicament  ' + (index + 1)}
                                                    classNames={{ input: 'customInput' }} />
                                            </Grid.Col>
                                        </Grid>
                                    ))
                                }

                                <Grid.Col span={12} mt={10}>
                                    <Button leftIcon={<PlusSquare />} size="lg"
                                        onClick={() => addMedicne()} color="yamnaGreen"
                                        radius="lg" pr={50}>Ajouter une medicament</Button>
                                </Grid.Col>
                                <Grid.Col span={12} mt={10}>
                                    <Button leftIcon={<Save />} type="submit"
                                        size="lg" radius="lg">Sauvegarder</Button>
                                </Grid.Col>
                            </Grid>
                        </form>
                    </div>

                }
            </Modal> */}
        </ >
    )
}

export default ShowAppointmentComponents
