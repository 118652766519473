import { Button, Group, LoadingOverlay, Select, Stack, Textarea, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { useEffect } from 'react'
import { Save } from 'react-feather'
import YamnaPhoneNumberField from '../../components/YamnaPhoneNumberField'
import { useProfileStore } from './profileStore'

interface ProfileForm {
    namePrefix: string
    firstName: string
    lastName: string
    phoneNumber: string
    email: string
    speciality: string
    description: string
}

export default function ProfileDetailsForm() {
    const store = useProfileStore()

    useEffect(() => {
        void store.controls.loadCurrentUser()
    }, [store.controls])

    const form = useForm<ProfileForm>({
        initialValues: {
            namePrefix: 'Dr.',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            speciality: '',
            description: ''
        },
        validate: {
            firstName: (value) => (value.trim().length > 0 ? null : 'Required'),
            lastName: (value) => (value.trim().length > 0 ? null : 'Required'),
            phoneNumber: (value) => {
                if (!value || value.trim().length <= 0) return 'Required'

                if (!isValidPhoneNumber(value, 'DZ')) return 'Invalid phone number'
            },
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
            speciality: (value) => (value.trim().length > 0 ? null : 'Required')
        },
        validateInputOnChange: true
    })

    useEffect(() => {
        if (!store.user) return

        form.setValues({
            email: store.user.email,
            description: store.user.description,
            firstName: store.user.firstName,
            lastName: store.user.lastName,
            namePrefix: store.user.namePrefix,
            phoneNumber: store.user.phoneNumber,
            speciality: store.user.speciality
        })
    }, [store.user])

    return <form onSubmit={form.onSubmit((value) => { void store.controls.updateProfile(value) })}
        style={{ height: '100%' }}>

        <LoadingOverlay
            visible={store.isFetchPending || store.saveInProgress}
            radius={30}
            loaderProps={{ color: 'yamnaPink' }} />

        <Stack
            justify={'space-between'}
            style={{ height: '100%' }}>

            <Group align={'stretch'}>
                <Select
                    required
                    size="lg"
                    radius="lg"
                    variant="filled"
                    placeholder="Prefix"
                    {...form.getInputProps('namePrefix')}
                    classNames={{ input: 'customInput' }}
                    data={[
                        'Dr.', 'Pr.'
                    ]}
                    style={{ maxWidth: 128 }}
                />

                <TextInput
                    required
                    size="lg"
                    radius="lg"
                    variant="filled"
                    placeholder="Last name"
                    {...form.getInputProps('lastName')}
                    classNames={{ input: 'customInput' }}
                    style={{ flexGrow: 1 }}
                />

                <TextInput
                    required
                    size="lg"
                    radius="lg"
                    variant="filled"
                    placeholder="First name"
                    {...form.getInputProps('firstName')}
                    classNames={{ input: 'customInput' }}
                    style={{ flexGrow: 1 }}
                />
            </Group>

            <Group align={'stretch'}>
                <YamnaPhoneNumberField
                    required={true}
                    country='DZ'
                    placeholder="Enter phone number"
                    {...form.getInputProps('phoneNumber')}
                    style={{ flexGrow: 1 }}
                ></YamnaPhoneNumberField>

                <TextInput
                    size="lg"
                    radius="lg"
                    type='email'
                    variant="filled"
                    placeholder="Enter an email"
                    {...form.getInputProps('email')}
                    classNames={{ input: 'customInput' }}
                    style={{ flexGrow: 1 }}
                />
            </Group>
            <TextInput
                required
                size="lg"
                radius="lg"
                variant="filled"
                placeholder="Speciality"
                {...form.getInputProps('speciality')}
                classNames={{ input: 'customInput' }}
            />

            <Textarea
                size="lg"
                radius="lg"
                variant="filled"
                placeholder="Description"
                {...form.getInputProps('description')}
                classNames={{ input: 'customInput' }}
                rows={4}
                autosize
                minRows={4}
            />

            <Button
                type='submit'
                size="lg" leftIcon={<Save />}
                loading={store.saveInProgress}
                radius="md">Save</Button>

        </Stack>
    </form>
}
