import { Button, Group, Stack, Text } from '@mantine/core'
import moment from 'moment'
import { useMemo, useState } from 'react'
import CalendarDay from './CalendarDay'

interface CalendarWeekProps {
    weekDay: string
    disabledDays?: string[]
}

export default function CalendarWeek({
    weekDay = moment().format(),
    disabledDays = []
}: CalendarWeekProps) {
    const [localWeekDay, setWeekDay] = useState(weekDay)

    const days = useMemo(() => {
        const days = []
        let currentDay = moment(localWeekDay).startOf('week')
        while (currentDay.isBefore(moment(localWeekDay).endOf('week'))) {
            days.push(currentDay.format())
            currentDay = currentDay.add(1, 'day')
        }
        return days
    }, [localWeekDay])

    return <Stack>
        <Group position='apart' align={'center'}>
            <Text fw={700} fz={24}>{ moment(localWeekDay).format('MMMM YYYY') }</Text>
            <Group spacing={0}>
                <Button
                    variant='subtle'
                    color={'dark'}
                    disabled={moment(localWeekDay).subtract(1, 'week').isBefore(moment(weekDay).startOf('week'))}
                    onClick={() => setWeekDay(moment(localWeekDay).subtract(1, 'week').format())}
                >
                    &#60;
                </Button>
                <Button
                    variant='subtle'
                    color={'dark'}
                    onClick={() => setWeekDay(moment(localWeekDay).add(1, 'week').format())}
                >
                    &#62;
                </Button>
            </Group>
        </Group>
        <Group>
            {days.map(day =>
                <Stack
                    key={day}
                    align='center'
                    spacing={'sm'}
                >
                    <Text color={'dimmed'}>{ moment(day).format('dd') }</Text>
                    <CalendarDay
                        day={day}
                        disabled={disabledDays.some((disabled) => moment(day).isSame(disabled, 'day')) }
                    ></CalendarDay>
                </Stack>)}
        </Group>
    </Stack>
}
