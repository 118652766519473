import { Button, createStyles, LoadingOverlay, ScrollArea, Select, Stack, TextInput } from '@mantine/core'
import { DatePicker, TimeInput } from '@mantine/dates'
import { useForm } from '@mantine/form'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { FaRegSave } from 'react-icons/fa'
import PhoneInput from 'react-phone-number-input/input'
import Appointment from '../../models/appointment'
import { useAppointmentsStore } from '../../stores/appointmentsStore'
import { usePatientsStore } from '../../stores/patientsStore'
import { useUsersStore } from '../../stores/usersStore'

interface EditAppointmentFormProps {
    appointment: Appointment
    closeModal: () => void
}

interface EditAppointmentFormData {
    doctorId: number
    appointmentDate: string
    patientId: number
}

interface EditPatientFormData {
    fullName: string
    address: string
    phone: string
    email: string
    birthday: string
    gender: 'Male' | 'Female' | ''
}

export type { EditAppointmentFormData, EditPatientFormData }

const useStyles = createStyles(() => ({
    phoneInput: {
        border: '1px solid transparent',
        backgroundColor: '#f1f3f5',
        fontFamily: 'Poppins',
        height: '50px',
        WebkitTapHighlightColor: 'transparent',
        lineHeight: '48px',
        appearance: 'none',
        resize: 'none',
        boxSizing: 'border-box',
        fontSize: '18px',
        width: '100%',
        color: '#000',
        display: 'block',
        textAlign: 'left',
        minHeight: '50px',
        paddingLeft: '16px',
        paddingRight: '16px',
        borderRadius: '16px',
        outline: 'none',
        '&::placeholder': {
            color: 'rgba(0, 0, 0, 0.3)'
        },
        '&:focus': {
            borderColor: '#49BAE4!important',
            outline: 'none'
        },
        '&:disabled': {
            backgroundColor: '#f1f3f5',
            color: '#909296',
            opacity: 0.6,
            outline: 'none'
        }
    }
}))

function EditAppointmentForm(props: EditAppointmentFormProps) {
    const usersStore = useUsersStore()
    const appointmentStore = useAppointmentsStore()
    const patientsStore = usePatientsStore()
    const [date, setDate] = useState(moment(props.appointment.appointmentAt).toDate())
    const [time, setTime] = useState(moment(props.appointment.appointmentAt).toDate())
    const [query, setQuery] = useState('')
    const { classes } = useStyles()

    const form = useForm<EditAppointmentFormData>({
        initialValues: {
            doctorId: props.appointment.doctorId,
            appointmentDate: props.appointment.appointmentAt,
            patientId: props.appointment.patientId
        }
    })

    const newPatientForm = useForm<EditPatientFormData>({
        initialValues: {
            fullName: '',
            address: '',
            email: '',
            phone: '',
            birthday: '',
            gender: ''
        }
    })

    const patientsSelectOptions = useMemo(() => {
        const newPatientOption = { label: 'New patient', value: '-1' }
        const patients: Array<{ value: string, label: string }> = patientsStore.patients.map(patient => ({
            label: patient.fullname,
            value: patient.id.toString()
        })).concat(newPatientOption)

        return patients
    }, [patientsStore.patients, patientsStore.isFindPending, query])

    useEffect(() => {
        const selectedPatient = patientsStore.patients.find(
            patient => patient.id.toString() === form.values.patientId.toString()
        )

        if (selectedPatient) {
            newPatientForm.setValues({
                fullName: selectedPatient.fullname,
                address: selectedPatient.adresse,
                birthday: selectedPatient.birthday,
                email: selectedPatient.email,
                gender: selectedPatient.sexe,
                phone: selectedPatient.phone
            })
        } else {
            newPatientForm.setValues({
                fullName: '',
                address: '',
                email: '',
                phone: '',
                birthday: '',
                gender: ''
            })
        }
    }, [form.values.patientId, patientsStore.patients])

    useEffect(() => {
        void usersStore.controls.fetchAvailableUsers(form.values.appointmentDate)
    }, [usersStore.controls, form.values.appointmentDate])

    useEffect(() => {
        patientsStore.controls.searchPatient({
            query
        })
    }, [patientsStore.controls, query])

    useEffect(() => {
        const dateTime = moment()
        dateTime.set('year', date.getFullYear())
        dateTime.set('month', date.getMonth())
        dateTime.set('date', date.getDate())
        dateTime.set('hour', time.getHours())
        dateTime.set('minutes', time.getMinutes())
        dateTime.set('seconds', time.getSeconds())
        dateTime.set('milliseconds', time.getMilliseconds())
        form.setFieldValue('appointmentDate', dateTime.format())
    }, [date, time])

    function submit(values: EditAppointmentFormData) {
        void appointmentStore.controls
            .updateAppointment(props.appointment.id, values, newPatientForm.values).then(props.closeModal)
    }

    return (
        <>
            <LoadingOverlay visible={appointmentStore.isCreatePending}></LoadingOverlay>
            <h2>Edit appointment</h2>
            <form
                onSubmit={form.onSubmit((values) => submit(values))}
                style={{ height: '100%' }}>
                <Stack
                    style={{ height: '90%' }}>

                    <ScrollArea type='hover' style={{ flexShrink: 1, flexGrow: 1 }}>
                        <Stack align={'stretch'}>
                            <Select
                                data={usersStore.users.map(user => ({
                                    label: `${user.firstName ?? ''} ${user.lastName ?? ''}`,
                                    value: user.id.toString()
                                }))}
                                disabled={usersStore.isFindPending}
                                required
                                size="lg"
                                radius="lg"
                                variant="filled"
                                placeholder={
                                    usersStore.isFindPending
                                        ? 'Loading ...'
                                        : (usersStore.users.length === 0 ? 'No doctors available' : 'Select a doctor')
                                }
                                value={form.values.doctorId.toString()}
                                onChange={(idAsString) => form.setFieldValue('doctorId', parseInt(idAsString ?? '-1'))}
                                classNames={{ input: 'customInput' }} />

                            <DatePicker
                                placeholder="Select date"
                                onChange={(date) => { if (date) setDate(date) }}
                                value={date}
                                allowFreeInput
                                clearable={false}
                                required
                                size='lg'
                                radius='lg'
                                classNames={{ input: classes.phoneInput }}/>

                            <TimeInput
                                value={time}
                                onChange={(time) => { if (time) setTime(time) } }
                                placeholder='Time'
                                clearable={false}
                                required
                                size='lg'
                                radius='lg'
                                classNames={{ input: classes.phoneInput }}/>

                            <Select
                                data={patientsSelectOptions}
                                required
                                size="lg"
                                radius="lg"
                                clearable={false}
                                searchable
                                nothingFound="No patient found"
                                onSearchChange={setQuery}
                                variant="filled"
                                placeholder="Select a patient"
                                value={form.values.patientId.toString()}
                                onChange={(patient) => { form.setFieldValue('patientId', Number.parseInt(patient ?? '-1')) }}
                                classNames={{ input: 'customInput' }}
                            />

                            <TextInput
                                required={form.values.patientId === -1}
                                readOnly={form.values.patientId !== -1}
                                size="lg"
                                radius="lg"
                                variant="filled"
                                placeholder="Patient full name"
                                {...newPatientForm.getInputProps('fullName')}
                                classNames={{ input: 'customInput' }}
                                disabled={form.values.patientId !== -1}
                            />

                            <TextInput
                                required={form.values.patientId === -1}
                                readOnly={form.values.patientId !== -1}
                                size="lg"
                                radius="lg"
                                variant="filled"
                                placeholder="Patient address"
                                {...newPatientForm.getInputProps('address')}
                                disabled={form.values.patientId !== -1}
                                classNames={{ input: 'customInput' }}
                            />

                            <PhoneInput
                                required={form.values.patientId === -1}
                                readOnly={form.values.patientId !== -1}
                                country='DZ'
                                className={classes.phoneInput}
                                disabled={form.values.patientId !== -1}
                                placeholder="Patient phone number"
                                {...newPatientForm.getInputProps('phone')}
                            ></PhoneInput>

                            <TextInput
                                required={form.values.patientId === -1}
                                readOnly={form.values.patientId !== -1}
                                size="lg"
                                radius="lg"
                                type='email'
                                variant="filled"
                                placeholder="Patient email"
                                disabled={form.values.patientId !== -1}
                                {...newPatientForm.getInputProps('email')}
                                classNames={{ input: 'customInput' }}
                            />

                            <DatePicker
                                placeholder="Patient birthdate"
                                onChange={(date) => {
                                    if (date) newPatientForm.setFieldValue('birthday', moment(date).format('YYYY-MM-DD'))
                                }}
                                value={newPatientForm.values.birthday ? moment(newPatientForm.values.birthday).toDate() : null}
                                allowFreeInput
                                required={form.values.patientId === -1}
                                disabled={form.values.patientId !== -1}
                                clearable={false}
                                size='lg'
                                radius='lg'
                                classNames={{ input: classes.phoneInput }}/>

                            <Select
                                data={[{ label: 'Male', value: 'Male' }, { label: 'Female', value: 'Female' }]}
                                required
                                size="lg"
                                radius="lg"
                                clearable={false}
                                disabled={form.values.patientId !== -1}
                                variant="filled"
                                placeholder="Gender"
                                {...newPatientForm.getInputProps('gender')}
                                classNames={{ input: 'customInput' }}
                            />
                        </Stack>

                    </ScrollArea>

                    <Button
                        loading={appointmentStore.isCreatePending}
                        type="submit"
                        fullWidth
                        radius="md"
                        size="lg"
                        styles={{
                            label: {
                                fontSize: 16,
                                lineHeight: 24
                            },
                            inner: {
                                alignContent: 'start'
                            }
                        }}
                        style={{
                            flexShrink: 0,
                            justifySelf: 'flex-end'
                        }}
                        leftIcon={<FaRegSave />}
                        m={0}
                        my={'md'}
                    >
                        Update an appointment
                    </Button>
                </Stack>
            </form>
        </>
    )
}

export default EditAppointmentForm
