import create from 'zustand'
import axiosClient from '../api/ApiClient'
import Patient from '../models/patient'

interface SearchProps { query: string }

export interface CreatePatientReqDto {
    fullname: string
    adresse: string
    phone: string
    email: string
    birthday: string
    sexe: 'Male' | 'Female'

    size?: number
    weight?: number
    imc?: string
    bloodType?: Patient['bloodType']
    diagnostic?: string
  }

interface PatientsStore {
  patients: Patient[]
  saveInProgress: boolean
  deletePending: boolean
  isFindPending: boolean
  controls: {
    createPatient: (
        data: CreatePatientReqDto
    ) => Promise<void>
    searchPatient: (
        data: SearchProps
    ) => void
    deletePatient: (
        id: number,
    ) => Promise<void>
  }
}

const usePatientsStore = create<PatientsStore>((set, get) => ({
    patients: [],
    saveInProgress: false,
    deletePending: false,
    isFindPending: false,
    controls: {
        createPatient: async (
            data
        ) => {
            set(() => ({
                saveInProgress: true
            }))
            return await axiosClient.post('/patient', data).then(response => response.data)
                .then(() => get().controls.searchPatient({ query: '' }))
                .finally(() => set({ saveInProgress: false }))
        },

        searchPatient: (data: SearchProps) => {
            set(() => ({
                isFindPending: true
            }))

            const params = new URLSearchParams()
            params.set('query', data.query)

            void axiosClient.get('/patient/search', { params }).then(response => response.data).then(patients => {
                set(() => ({
                    patients,
                    isFindPending: false
                }))
            })
        },

        deletePatient: async (
            patientId
        ) => {
            set({ deletePending: true })
            return await axiosClient.delete(`/patient/${patientId}`)
                .then(() => get().controls.searchPatient({ query: '' }))
                .finally(() => set({ deletePending: false }))
        }
    }
}))

export { usePatientsStore }
