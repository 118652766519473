import create from 'zustand'
import User from '../../models/user'
import axiosClient from '../../api/ApiClient'

interface SearchProps { query: string }

interface StaffStore {
  users: User[]
  saveInProgress: boolean
  deletePending: boolean
  isFindPending: boolean
  controls: {
    searchStaff: (
        data: SearchProps
    ) => void
    deleteStaff: (
        id: number,
    ) => Promise<void>
  }
}

const useStaffStore = create<StaffStore>((set, get) => ({
    users: [],
    saveInProgress: false,
    deletePending: false,
    isFindPending: false,
    controls: {
        searchStaff: (data: SearchProps) => {
            set(() => ({
                isFindPending: true
            }))

            const params = new URLSearchParams()
            params.set('query', data.query)

            void axiosClient.get('/user/search', { params }).then(response => response.data).then(patients => {
                set(() => ({
                    users: patients,
                    isFindPending: false
                }))
            })
        },

        deleteStaff: async (
            patientId
        ) => {
            set({ deletePending: true })
            return await axiosClient.delete(`/patient/${patientId}`)
                .then(() => get().controls.searchStaff({ query: '' }))
                .finally(() => set({ deletePending: false }))
        }
    }
}))

export { useStaffStore }
