import React, { useState, useCallback, useEffect } from 'react';
import { listPaymentService } from '../../api/services/Payment';
import { Grid, Table, Group, Button, Drawer, TextInput, Select, ActionIcon, createStyles, ScrollArea, Modal, Center } from '@mantine/core';
import { PlusSquare, XCircle, Save, Eye, Edit2, Trash } from 'react-feather';
function Payments() {
    const [paymentsData, setPaymentsData] = useState([])
    const loadPaymentsData = useCallback(async () => {
        setPaymentsData(await listPaymentService())
    }, [])

    useEffect(() => {
        loadPaymentsData();
    }, [loadPaymentsData])

    return (
        <Grid className='patients' grow mt={30}>
            <Grid.Col span={3}></Grid.Col>
            <Grid.Col span={9}>
                <div className="box">
                    <Grid grow>
                        <Grid.Col span={12}>
                            <Group position="apart">
                                <h2>Peiments</h2>
                            </Group>
                        </Grid.Col>
                        <Grid.Col>
                            <Table striped highlightOnHover horizontalSpacing="xl" verticalSpacing="md" className="cusTomTable">
                                <thead>
                                    <tr>
                                        <th>Nom et prénom</th>
                                        <th>À Payer</th>
                                        <th>Reste à payer</th>
                                        <th>Médecin</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        paymentsData.map((p, index) => {
                                            return (
                                                < tr key={p.id} >
                                                    <td>{p.appointment.patient.fullname}</td>
                                                    <td>{p.toPay} DA</td>
                                                    <td>{p.leftToPay} DA</td>
                                                    <td>{p.appointment.doctor.firstName} {p.appointment.doctor.lastName}</td>
                                                    <td>
                                                        <Group>
                                                            <ActionIcon color="yamnaGreen" radius="xl" variant="filled">
                                                                <Eye size={15} />
                                                            </ActionIcon>
                                                            <ActionIcon color="yamnaGreen" radius="xl" variant="filled">
                                                                <Edit2 size={15} />
                                                            </ActionIcon>
                                                            <ActionIcon color="yamnaGreen" radius="xl" variant="filled">
                                                                <Trash size={15} />
                                                            </ActionIcon>
                                                        </Group>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Grid.Col>
                    </Grid>
                </div>

            </Grid.Col>
        </Grid >
    );
}

export default Payments;