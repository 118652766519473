import { DefaultProps, Grid, Text } from '@mantine/core'
import User from '../models/user'

export default function Doctor(props: DefaultProps & { doctor: User }) {
    return (
        <Grid style={{ textAlign: 'start' }}>
            <Grid.Col span={7}>
                <Text size='xl' weight={700}>{props.doctor.namePrefix} {props.doctor.firstName} {props.doctor.lastName}</Text>

                <Text
                    size='md'
                    weight={700}
                    color={'dimmed'}
                >{props.doctor.speciality}</Text>
            </Grid.Col>
            <Grid.Col span={5}>
                <Text
                    size='md'
                    weight={700}
                    color={'dimmed'}
                >{props.doctor.clinic?.phoneNumber}</Text>

                <Text
                    size='md'
                    weight={700}
                    color={'dimmed'}
                >{props.doctor.email}</Text>

                <Text
                    size='md'
                    weight={700}
                    color={'dimmed'}
                >{props.doctor.clinic?.address}</Text>
            </Grid.Col>
        </Grid>
    )
}
