import create from 'zustand'
import client from '../../api/ApiClient'
import Statistics from '../../models/statistics'

interface StatisticsQuery {
    start: string
    end: string
}

interface StatisticsStore {
  statsData: Statistics | null
  isFetchPending: boolean
  errorMessage: string | null
  controls: {
    fetchStatistics: (query: StatisticsQuery) => Promise<void>
  }
}

const useStatisticsStore = create<StatisticsStore>((set, get) => ({
    statsData: null,
    isFetchPending: false,
    errorMessage: null,
    controls: {
        fetchStatistics: async (query) => {
            set({
                isFetchPending: true,
                errorMessage: null
            })
            await client
                .get(`/statistics?start=${query.start}&end=${query.end}`)
                .then((response) => {
                    const data = response.data

                    set({
                        statsData: data,
                        isFetchPending: false
                    })
                })
                .catch((error) => {
                    set(() => ({
                        errorMessage: error.errorMessage
                    }))
                })
                .finally(() => set(() => ({ isFetchPending: false })))
        }
    }
}))

export { useStatisticsStore }
