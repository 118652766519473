import { AxiosResponse } from 'axios'
import create from 'zustand'
import axiosClient from '../../../api/ApiClient'
import Patient from '../../../models/patient'

export interface PatchPatientReqDto {
    fullname: string
    adresse: string
    phone: string
    email: string
    birthday: string
    sexe: 'Male' | 'Female'

    size?: number
    weight?: number
    imc?: string
    bloodType?: Patient['bloodType']
    diagnostic?: string
  }

interface EditPatientStore {
  saveInProgress: boolean
  controls: {
    patchPatient: (
        id: number,
        data: PatchPatientReqDto
    ) => Promise<void>
  }
}

const useEditPatientStore = create<EditPatientStore>((set, get) => ({
    saveInProgress: false,
    controls: {
        patchPatient: async (
            id,
            data
        ) => {
            set({ saveInProgress: true })

            await axiosClient.patch(`/patient/${id}`, data).then((response: AxiosResponse<Patient>) => response.data)
                .finally(() => set({ saveInProgress: false }))
        }
    }
}))

export { useEditPatientStore }
